import React from "react";
import "../../Styles/T2/T2Toss.css";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import t2_teamb_logo from "../../Assets/T2/t2_teamb_logo.png";
import before_start_bg from "../../Assets/T2/before_start_bg.png";
import t2_toss_title_bg from "../../Assets/T2/t2_toss_title_banner.png";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import socketIOClient from "socket.io-client";
import t2_default_logo from "../../Assets/T2/t2_team_default_logo.png";
import he from "he";
import T2Teams from "./T2Teams";
function T2Toss() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    result: "",
    toss_won_team: "",
    toss_decision: "",
    teama_short_name: "",
    teamb_short_name: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        result: mainData.RESULT,
        toss_decision: data.TOSS.TOSS_DECISION,
        toss_won_team: he.decode(data.TOSS.TOSS_WON_TEAM),
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  return (
    <>
    {showTicker ? <div className="d-flex position-relative" id="t2_toss_container">
      <div
        id="t2_toss_bottom_layer"
        className="d-flex justify-content-center align-items-center text-uppercase"
      >
        {" "}
        TOSS WON BY {decodeNames(he.decode(teamName.toss_won_team))} - OPT TO{" "}
        {teamName.toss_decision}
      </div>
      <div id="t2_toss_bg">
        <img src={before_start_bg} alt="t2_toss_bg" width="100%" />
      </div>
      <div id="t2_toss_teama_short_name">
        {decodeNames(he.decode(teamName.teama_short_name))}
      </div>
      <div id="t2_toss_team_name">
        ({decodeNames(he.decode(teamName.teama_name))})
      </div>
      <div id="t2_toss_vs_text">VS</div>
      <div id="t2_toss_teamb_short_name">
        {decodeNames(he.decode(teamName.teamb_short_name))}
      </div>
      <div id="t2_toss_teamb_name">
        ({decodeNames(he.decode(teamName.teamb_name))})
      </div>
      <div id="t2_toss_logos">
        <div id="t2_toss_logo_bg">
          <img
            src={process.env.REACT_APP_API_URL + teamName.teama_logo}
            alt="lg"
            width="100%"
            onError={(e) => (e.target.src = t2_default_logo)}
          />
        </div>
        <div>
          <img
            src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
            alt="teama_logo"
            width="100%"
            onError={(e) => (e.target.src = t2_default_logo)}
          />
        </div>
      </div>
      <div id="t2_toss_title_bg">
        <img src={t2_toss_title_bg} alt="t2_toss_bg" width="100%" />
      </div>
      <div id="t2_toss_title_text">TOss update</div>
    </div>:<T2Teams />}
    
    </>
   
  );
}

export default T2Toss;
