import React, { useEffect, useState } from "react";
// import scorecard_bg from "../../Assets/scorecard_bg.png";
import player_bg from "../../Assets/player_bg.png";
import player_logo from "../../Assets/player_logo.png";
import "../../Styles/T1/PlayerInfo.css";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import player_default_logo from "../../Assets/player_default_logo.png";

function PlayerInfo(props) {
  let [searchParams] = useSearchParams();
  let player_id = searchParams.get("player_id");

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const [summary, setSummary] = useState({
    player_name: "",
    player_type: "",
    matches: "",
    innings: "",
    runs: "",
    highest_runs: "",
    no_of_fifties: "",
    no_of_hundreds: "",
    player_img: "",
    non_striker_id: "",
  });
  // console.log(props.strikerData.PLAYER_ID,"k")
let striker_id = props.strikerData;

  const reqData = {
    mod: "PlayerCareer",
    actionType: "player-career",
    subAction: JSON.stringify({ PLAYER_ID: striker_id.PLAYER_ID }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      // console.log(res, "res");
      let responseData = res.data.XSCData.CAREER_BATTING;
      setSummary({
        player_name: responseData.FULL_NAME,
        player_type: responseData.STYLE_SHORT_NAME,
        player_img: responseData.IMAGE_URL,
        runs: responseData.RUNS,
        highest_runs: responseData.HIGH_SCORE,
        no_of_hundreds: responseData,
        matches: responseData.MATCHES,
        innings: responseData.INNINGS,
      });
      
    });

  }, [0]);
  const handleImage = (e) => {
    e.target.src = player_default_logo;
  };

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  return (
    <div className="" id="t1_player_info_container">
      {showTicker ? (
        <div className="my-auto px-5 ">
          {" "}
          <div id="player_white_bg">
            <img
              src={player_bg}
              style={{ zIndex: "1", position: "relative" }}
            />
          </div>
          <div className="position-absolute top-0 ">
            <div className="">
              <div id="player_logo_border" className=" position-absolute"></div>
              <img
                id="player_logo"
                className="position-relative"
                src={process.env.REACT_APP_API_URL + summary.player_img}
                alt="player-logo"
                width="100%"
                onError={handleImage}
              ></img>
            </div>
            <div id="player_name" className="">
              {decodeNames(summary.player_name)}
            </div>
            <div id="player_info" className="">
              {summary.player_type}
            </div>
            <div id="row_width" className="row">
              <div id="player_matches" className="col-4 ">
                {summary.matches}
                <div id="player_matches_text" className="">
                  Matches
                </div>
              </div>
              <div id="player_inngs" className="col-4">
                {summary.innings}
                <div id="player_ings_text" className="">
                  INNS
                </div>
              </div>{" "}
              <div id="player_runs" className="col-4">
                {summary.runs}
                <div id="player_runs_text" className="">
                  Runs
                </div>
              </div>
              <div id="player_highest_runs" className="col-4 ">
                {summary.highest_runs}*
                <div id="player_highest_runs_text" className="mt-2">
                  h/r
                </div>
              </div>
              <div id="no_of_50" className="col-4 ">
                {summary.no_of_hundreds["50S"]}
                <div id="text_50" className="">
                  50's
                </div>
              </div>
              <div id="no_of_100" className="col-4">
                {summary.no_of_hundreds["100S"]}
                <div id="text_100" className="">
                  100's
                </div>
              </div>
            </div>
          </div>
          <div
            id="player_outer_gradient_layout"
            className="position-absolute "
          ></div>
          <div
            id="player_inner_gradient_layout"
            className="position-absolute"
          ></div>
          <div
            id="next_crease_text"
            className="position-absolute bottom-0 translate-middle-y  "
          >
            NExt in the crease
          </div>
        </div>
      ) : (
        ""
      )}
    </div>
  );
}
export default PlayerInfo;
