import React, { useState, useEffect } from "react";
import scorecardbg from "../../Assets/scorecard_bg.png";
import batting_team_logo from "../../Assets/batting_team_logo.png";
import toss_update_bg_gradient from "../../Assets/toss_update_bg_gradient.png";
import "../../Styles/T1/TossInfo.css";
import { useSearchParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import he from "he";
function TossBottom(props) {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_logo: "",
    teama_short_name: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    result: "",
    toss_won_team: "",
    toss_decision: "",
    match_type: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      // console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        result: mainData.RESULT,
        toss_decision: data.TOSS.TOSS_DECISION,
        toss_won_team: he.decode(data.TOSS.TOSS_WON_TEAM),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        match_type: mainData.TYPE,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);
  const decodeNames = (x) => {
    // const [teamImage, setTeamImage] = useState({batting_team_logo})
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  return (
    <div
      className="position-relative d-flex"
      style={{
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* <div id="toss_update_gradient_bg">
        <img src={toss_update_bg_gradient} alt="toss_gradient" width="100%" />
      </div> */}
      {/* <div id="toss_update_bg">
        <div id="toss_update_text">Toss Update</div>
      </div> */}

      {/* <div id="teama_logo_bg">
        <span
          id="toss_teama_outer_gradient"
          className="position-absolute  z-99 "
        >
          <div id="toss_teama_inner_gradient">
            <div
              id="toss_teama_logo"
              className="d-flex justify-content-center align-items-center"
            >
              <img
                src={process.env.REACT_APP_API_URL + teamName.teama_logo}
                alt="logo"
                width="100%"
                style={{ borderRadius: "50%" }}
              ></img>
            </div>
          </div>
        </span>{" "}
      </div> */}
      {/* <div id="teamb_logo_bg">
        <span
          id="toss_teamb_outer_gradient"
          className="position-absolute  z-99 "
        >
          <div id="toss_teamb_inner_gradient">
            <div
              id="toss_teamb_logo"
              className="d-flex justify-content-center align-items-center"
            >
              <img
                src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
                alt="logo"
                width="100%"
                style={{ borderRadius: "50%" }}
              ></img>
            </div>
          </div>
        </span>{" "}
      </div> */}

      <div className="position-absolute bottom-0 start-0 d-flex flex-column ">
        <div id="toss_green_layer" className="d-flex justify-content-center">
          <div className=" ">
            <div className="" id="toss_teama_short_name">
              {he.decode(decodeNames(teamName.teama_short_name))}
            </div>{" "}
            <span id="toss_teama_full_name" className="PX-2">
              {decodeNames(he.decode("("+teamName.teama_name+")"))}
            </span>
          </div>
          <div id="toss_vs_text_bg" className="">
            <span id="toss_vs_text">VS</span>
          </div>
          <div className=" ">
            <div id="toss_teamb_short_name">
              {decodeNames(he.decode(teamName.teamb_short_name))}
            </div>{" "}
            <span id="toss_teamb_full_name" className=" PX-2">
              {he.decode(decodeNames("("+teamName.teamb_name+")"))}
            </span>
          </div>
        </div>
        <div
          id="toss_blue_layer"
          className="d-flex align-items-center justify-content-center text-white"
        >
          {`TOSS WON BY ${he.decode(
            decodeNames(teamName.toss_won_team)
          )} AND CHOOSE TO ${teamName.toss_decision}`}
        </div>
      </div>
    </div>
  );
}
export default TossBottom;
