import React from "react";
import "../../Styles/T2/T2BeforeStart.css";
import before_start_bg from "../../Assets/T2/before_start_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import t2_teamb_logo from "../../Assets/T2/t2_teamb_logo.png";
import socketIOClient from "socket.io-client";
import { useSearchParams } from "react-router-dom";
import team_deault_img from "../../Assets/team_logo.png";
import { useState, useEffect } from "react";
import T2MatchSummary from "./T2MatchSummary";
import t2_default_logo from "../../Assets/T2/t2_team_default_logo.png";
import he from "he";
function T2InningsBreakBottom() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    result: "",
    teama_short_name: "",
    teamb_short_name: "",
    total_over: "",
    status: "",
    target:"",
    total:""
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        teama_short_name: mainData.TEAM_A.TEAM_NICK_NAME,
        teamb_short_name: mainData.TEAM_B.TEAM_NICK_NAME,
        total_over: mainData.TOTAL_OVER,
        result: mainData.RESULT,
        status: mainData.STATUS,
        total_over: mainData.TOTAL_OVER,

        target: data.BATTING.TARGET,
      });
      console.log(process.env.REACT_APP_API_URL + teamName.teamb_logo, "ss");
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);

  const [teamLogo, setTeamLogo] = useState(
    process.env.REACT_APP_API_URL + teamName.teamb_logo
  );
  const handleTeamLogo = () => {
    setTeamLogo(team_deault_img);
  };
  const [teambLogo, setTeambLogo] = useState(
    "https://dev-api.cricdost.com" + teamName.teamb_logo
  );
  const handleTeambLogo = () => {
    setTeambLogo(team_deault_img);
  };
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  return (
    <>
      {/* {teamName.current_inning === 1 ||
      teamName.result === "Innings break" ||
      (teamName.current_inning === 2 && teamName.bowler === 0) ? (
        <div className="position-relative d-flex" id="before_start_container">
          <div
            id="t2_bottom_layer"
            className="d-flex justify-content-center align-items-center"
          >
            {" "}
            MATCH WILL START SOON - {teamName.total_over} OVERS
          </div>
          <div id="t2_teams_bg">
            <img src={before_start_bg} alt="before_start_bg" width="100%" />
          </div>
          <div id="t2_start_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt=""
              onError={() => handleTeamLogo}
            />
          </div>
          <div id="t2_start_teama_short_name">{teamName.teama_short_name}</div>
          <div id="t2_start_teama_name">({teamName.teama_name})</div>
          <div id="t2_start_vs_text">VS</div>
          <div id="t2_start_teamb_short_name">{teamName.teamb_short_name}</div>
          <div id="t2_start_teamb_name">({teamName.teamb_name})</div>
          <div id="t2_start_teamb_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt="team2_logo"
              onError={handleTeamLogo}
            />
          </div>
        </div>
      ) : (
        <T2MatchSummary />
      )} */}
      {/* {teamName.status === "Completed" ? (
        <T2MatchSummary />
      ) : ( */}
        <div className="position-relative d-flex" id="before_start_container">
          <div
            id="t2_bottom_layer"
            className="d-flex justify-content-center align-items-center"
          >
            {" "}
            
            {he.decode(decodeNames(teamName.teamb_name + "  "))}{" "}
            <span className="ps-1">
              {" " + "need"} {teamName.target} runs in {teamName.total_over}{" "}
              overs
            </span>
          </div>
          <div id="t2_teams_bg">
            <img src={before_start_bg} alt="before_start_bg" width="100%" />
          </div>
          <div id="t2_start_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt=""
              onError={(e) => (e.target.src = t2_default_logo)}
            />
          </div>
          <div id="t2_start_teama_short_name">
            {he.decode(decodeNames(teamName.teama_short_name))}
          </div>
          <div id="t2_start_teama_name">
            ({he.decode(decodeNames(teamName.teama_name))})
          </div>
          <div id="t2_start_vs_text">VS</div>
          <div id="t2_start_teamb_short_name">{teamName.teamb_short_name}</div>
          <div id="t2_start_teamb_name">({teamName.teamb_name})</div>
          <div id="t2_start_teamb_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt=""
              onError={(e) => (e.target.src = t2_default_logo)}
            />
          </div>
        </div>
      {/* )} */}
    </>
  );
}
export default T2InningsBreakBottom;
