import React, { useEffect, useState } from "react";
import player_info_bg from "../../Assets/T2/player_info_bg.png";
import "../../Styles/T2/T2PlayerInfo.css";
import player_info_logo from "../../Assets/T2/player_info_logo.png";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import qs from "qs";
function T2PlayerInfo(props) {
  let [searchParams] = useSearchParams();
  let player_id = searchParams.get("player_id");

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  let striker_id = props.strikerData;
  console.log(striker_id, "jk");

  const [summary, setSummary] = useState({
    player_name: "",
    player_type: "",
    matches: "",
    innings: "",
    runs: "",
    highest_runs: "",
    no_of_fifties: "",
    no_of_hundreds: "",
    player_img: "",
    non_striker_id: "",
  });
  // console.log(props.strikerData.PLAYER_ID,"k")

  const reqData = {
    mod: "PlayerCareer",
    actionType: "player-career",
    subAction: JSON.stringify({ PLAYER_ID: striker_id.PLAYER_ID }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData.CAREER_BATTING;
      setSummary({
        player_name: responseData.FULL_NAME,
        player_type: responseData.STYLE_SHORT_NAME,
        player_img: responseData.IMAGE_URL,
        runs: responseData.RUNS,
        highest_runs: responseData.HIGH_SCORE,
        no_of_hundreds: responseData,
        matches: responseData.MATCHES,
        innings: responseData.INNINGS,
      });
    });
  }, [0]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  return (
    <>
      {showTicker ? (
        <div className="d-flex position-relative" id="t2_player_info">
          <div id="t2_player_info_bg_img">
            <img src={player_info_bg} />
          </div>
          <div id="t2_player_info_name_bg">
            <div id="t2_player_name" className="ps-2">{decodeNames(summary.player_name)}</div>

            <img src={player_info_logo} />
            {/* <div id="t2_player_type">{summary.player_type}</div> */}
            <div id="t2_player_info_details_bg">
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  Matches
                  <div id="t2_player_info_matches">{summary.matches}</div>
                </div>
              </div>
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  innings
                  <div id="t2_player_info_matches">{summary.innings}</div>
                </div>
              </div>
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  runs<div id="t2_player_info_matches">{summary.runs}</div>
                </div>
              </div>
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  hr
                  <div id="t2_player_info_matches">{summary.highest_runs}*</div>
                </div>
              </div>
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  50's
                  <div id="t2_player_info_matches">
                    {summary.no_of_hundreds["50S"]}
                  </div>
                </div>
              </div>
              <div id="t2_player_info_matches_bg">
                <div id="t2_player_info_matches_text">
                  100's
                  <div id="t2_player_info_matches">
                    {summary.no_of_hundreds["100S"]}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
export default T2PlayerInfo;
