import React, { useState, useEffect, useContext } from "react";
import "../../Styles/T2/Ticker.css";
import t2_bg_img from "../../Assets/T2/t2_ticker_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
// import t2_teamb_logo from "../../Assets/T2/t2_teamb_logo.png";
import t2_stirker_arrow from "../../Assets/T2/t2_striker_arrow.png";
import { SocketContext } from "../../socket";
import { useNavigate, useSearchParams } from "react-router-dom";
// import BeforeStart from "./BeforStart";
// import T2Toss from "./T2Toss";
// import T2BattingSummary from "./T2BattingSummary";
// import T2Four from "./Four";
import t2_team_default_logo from "../../Assets/T2/t2_team_default_logo.png";
function Ticker2() {
  const socket = useContext(SocketContext);
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  const navigate = useNavigate();

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const [showTicker, setShowTicker] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
    }, 6000);
  }, []);

  const [scores, setScores] = useState({
    teamA_name: "",
    teamB_name: "",
    striker_name: "",
    striker_runs: "",
    striker_balls: "",
    curr_runs: "",
    wickets: "",
    overs: "",
    non_striker_name: "",
    non_striker_runs: "",
    non_striker_balls: "",
    bowler_name: "",
    bowler_wickets: "",
    bowler_runs: "",
    bowler_overs: "",
    last_ball: "",
    toss_detail: "",
    result: "",
    current_inning: "",
    information: "",
    toss_decision: "",
    striker: "",
    status: "",
    curr_overs: [],
    teama_short_name: "",
    teamb_short_name: "",
    extras_type: "",
    match_type: "",
  });

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("live_score_new", (data) => {
      console.log(data);
      setScores({
        striker_name: data.STRIKER.NAME,
        striker_runs: data.STRIKER.RUNS,
        striker_balls: data.STRIKER.BALLS,
        non_striker_name: data.NONSTRIKER.NAME,
        non_striker_balls: data.NONSTRIKER.BALLS,
        non_striker_runs: data.NONSTRIKER.RUNS,
        wickets: data.BATTING.WICKETS,
        overs: data.BATTING.OVERS,
        bowler_name: data.BOWLER.NAME,
        bowler_runs: data.BOWLER.RUNS,
        bowler_wickets: data.BOWLER.WICKETS,
        bowler_overs: data.BOWLER.OVERS,
        curr_overs: data.CURRENT_OVER,
        curr_runs: data.BATTING.RUNS,
        teamA_name: data.TEAM_A.TEAM_A_NAME,
        teamB_name: data.TEAM_B.TEAM_B_NAME,
        result: data.RESULT,
        last_ball: data.LAST_BALL,
        toss_detail: data.RESULT,
        information: data.INFORMATION,
        toss_decision: data.TOSS.TOSS_DECISION,
        striker: data.STRIKER,
        current_inning: data.CURRENT_INNING,
        status: data.STATUS,
        teama_short_name: data.TEAM_A.TEAM_NICK_NAME,
        teamb_short_name: data.TEAM_B.TEAM_NICK_NAME,
        match_type: data.TYPE,
      });
    });
    socket.emit("join", "match_id_", match_id);
    socket.emit("get_score", match_id);

    // { scores.status === "COMPLETED"
    //     ? navigate(`/match-summary? ${match_id}`)
    //     : ""}
  }, [0]);

  const maxLetterCount = 10; // Set the maximum letter count
  const textContainer = document.getElementById("t2_striker_name");

  if (textContainer) {
    if (textContainer.textContent.length > maxLetterCount) {
      const truncatedText = textContainer.textContent.substring(
        0,
        maxLetterCount
      );
      textContainer.textContent = truncatedText + "...";
    }
  }
  const nonstrikerName = document.getElementById("t2_non_striker_name");

  if (nonstrikerName) {
    if (nonstrikerName.textContent.length > maxLetterCount) {
      const truncatedText = nonstrikerName.textContent.substring(
        0,
        maxLetterCount
      );
      nonstrikerName.textContent = truncatedText + "...";
    }
  }
  const bowlerName = document.getElementById("t2_bowler_name");

  if (bowlerName) {
    if (bowlerName.textContent.length > maxLetterCount) {
      const truncatedText = bowlerName.textContent.substring(0, maxLetterCount);
      bowlerName.textContent = truncatedText + "...";
    }
  }
  return (
    <>
      {/* <T2Toss /> */}
      {/* {scores.result == null && scores.information == null ? (
        <BeforeStart />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <T2Toss />
      ) : (
        ""
      )} */}
      {/* {scores.result === "Innings break" ? <T2BattingSummary /> : ""}
      {scores.status === "Completed" ? <T2BattingSummary /> : ""} */}
      {/* {scores.result === null && scores.information === null ? (
        <BeforeStart />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <T2Toss />
      ) :  (
        ""
      )} */}
      <div id="t2_left"></div>
      <div id="t2_left_border"></div>
      <div id="t2_center"></div>
      <div id="t2_right"></div>

      <div id="t2_container_bg">
        {/* <T2Four /> */}
        <div id="t2_bg_img">
          <img src={t2_bg_img} alt="t2_ticker" width="100%" />
        </div>
        <div id="t2_teama_logo">
          <img
            src={process.env.REACT_APP_API_URL + t2_teama_logo}
            // src={t2_teama_logo}
            onError={(e) => (e.target.src = t2_team_default_logo)}
            alt="lossgo"
            style={{ height: "10vh", width: "5vw" }}
          />
        </div>
        <div id="t2_striker_arrow">
          <img
            src={t2_stirker_arrow}
            style={{ height: "1.6vh", width: "0.8vw" }}
            alt="striker_arrow"
            width="100%"
          />
        </div>
        <div id="t2_striker_name">{scores.striker_name}</div>
        <div id="t2_non_striker_name">{scores.non_striker_name}</div>
        <div id="t2_striker_runs">{scores.striker_runs}</div>
        <div id="t2_striker_balls">{scores.striker_balls}</div>
        <div id="t2_non_striker_runs">{scores.non_striker_runs}</div>
        <div id="t2_non_striker_balls">{scores.non_striker_balls}</div>
        <div id="t2_teama_name">{scores.teamA_name}</div>
        <div id="team_current_runs_balls">
          {/* {scores.curr_runs}-{scores.wickets} */}
          <span id="t2_team_runs_wickets" align="center">
            {scores.curr_runs}-{scores.wickets}
          </span>
        </div>
        <div id="t2_vs_text">vs</div>
        <div id="t2_teamb_name">{scores.teamB_name}</div>
        <div id="t2_white_gradient_line"></div>
        <div id="t2_overs_text">overs</div>
        <div id="t2_curr_overs">{scores.overs}</div>
        <div id="t2_bowler_name">{scores.bowler_name}</div>
        <div id="t2_bowler_runs">
          {scores.bowler_runs}-{scores.bowler_wickets}
        </div>
        <div id="t2_bowler_line">|</div>
        <div id="t2_bowler_overs">{scores.bowler_overs}</div>
        <div id="t2_teamb_logo">
          <img
            src={process.env.REACT_APP_API_URL + t2_teama_logo}
            // src={t2_teamb_logo}
            onError={(e) => (e.target.src = t2_team_default_logo)}
            alt=""
            style={{ height: "10vh", width: "5vw", borderRadius: "5vw" }}
          />
        </div>

       
        <div
          id="t2_current_balls"
          style={{
            flex: "",
            overflowX: "scroll",
            width: "31.5vh",
            // whiteSpace: "nowrap",
            display: "flex",

            position: "absolute",
            direction: "rtl",
            justifyContent: "right",
            // left:"75vw"
          }}
        >
          <div
            className="d-flex justify-content-left"
            style={{ direction: "ltr" }}
          >
            {scores.curr_overs
              .slice(0)
              .reverse()
              .map((overs) => {
                if (
                  overs.RUNS === 4 &&
                  overs.WICKET === 0 &&
                  overs.EXTRAS === 0
                ) {
                  return (
                    <>
                      <div
                        id="t2_single_balls"
                        style={{
                          // backgroundColor:"blue",
                          backgroundImage:
                            "linear-gradient(180deg, #1B863A 0%, #105F26 100%)",
                          color: "white",
                          // marginLeft: "1.3vh",
                          borderRadius: "40px",
                          height: "40px",
                          width: "40px",
                          backgroundSize: "cover",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          
                        }}
                      >
                        <span>{overs.RUNS}</span>
                      </div>
                    </>
                  );
                } else if (
                  overs.RUNS === 6 &&
                  overs.WICKET === 0 &&
                  overs.EXTRAS === 0
                ) {
                  return (
                    <>
                      {" "}
                      <div
                        id="t2_single_balls"
                        style={{
                          // backgroundColor:"blue",
                          backgroundImage:
                            "linear-gradient(180deg, #1B863A 0%, #105F26 100%)",
                          color: "white",
                          // marginLeft: "1.3vh",
                          borderRadius: "40px",
                          height: "40px",
                          width: "40px",
                          backgroundSize: "cover",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <span
                        // style={{
                        //   height: "4.5vh",
                        //   width: "4.5vh",
                        //   display: "flex",
                        //   alignItems: "center",
                        //   justifyContent: "center",
                        // }}
                        >
                          {overs.RUNS}
                        </span>
                      </div>
                    </>
                  );
                } else if (overs.WICKET === 1) {
                  return (
                    <>
                      {" "}
                      <div
                        id="t2_single_balls"
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{
                          // backgroundColor:"blue",
                          backgroundImage:
                            "linear-gradient(180deg, #1B863A 0%, #105F26 100%)",
                          color: "white",
                          // marginLeft: "1.3vh",
                          borderRadius: "40px",
                          height: "40px",
                          width: "40px",
                          backgroundSize: "cover",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }} // style={{
                        //   background: "#D93025",
                        //   color: "white",
                        //   marginLeft: "1.3vh",
                        // }}
                      >
                        {overs.WICKET === 1 &&
                        overs.RUNS === 0 &&
                        overs.EXTRAS === 0 ? (
                          <>
                            <span
                            // style={{
                            //   height: "4.5vh",
                            //   width: "4.5vh",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            // }}
                            >
                              {"W"}
                            </span>
                          </>
                        ) : (
                          <>
                            <span
                            // style={{
                            //   fontSize: "1.5vh",
                            //   lineHeight: "1.7vh",
                            //   fontWeight: "400",
                            //   height: "4.5vh",
                            //   width: "4.5vh",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            // }}
                            >
                              {overs.TOTAL_RUNS}
                            </span>
                            <span
                            // style={{
                            //   fontSize: "1.5vh",
                            //   lineHeight: "1.7vh",
                            //   fontWeight: "400",
                            //   display: "flex",
                            //   alignItems: "center",
                            //   justifyContent: "center",
                            // }}
                            >
                              {"W"}
                            </span>
                          </>
                        )}
                      </div>
                    </>
                  );
                } else
                  return (
                    <>
                      {" "}
                      <div id="" className="d-flex flex-column ">
                        {overs.EXTRAS !== 0 ? (
                          <div id="t2_single_balls"
                            className=""
                            style={{
                              // backgroundColor:"blue",
                              backgroundImage:
                                "linear-gradient(180deg, #1B863A 0%, #105F26 100%)",
                              color: "white",
                              // marginLeft: "1.3vh",
                              borderRadius: "40px",
                              height: "40px",
                              width: "40px",
                              backgroundSize: "cover",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              flexDirection:"column",
                              fontSize:"1.4vh"
                            }}
                          >
                            <div className="">{overs.TOTAL_RUNS}</div>
                            <div className="t2_extras_balls">
                              {overs.EXTRAS_TYPE}
                            </div>
                          </div>
                        ) : (
                          <span
                            id="t2_single_balls"
                            style={{
                              // backgroundColor:"blue",
                              backgroundImage:
                                "linear-gradient(180deg, #1B863A 0%, #105F26 100%)",
                              color: "white",
                              // marginLeft: "1.3vh",
                              borderRadius: "40px",
                              height: "40px",
                              width: "40px",
                              backgroundSize: "cover",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                            }}
                          >
                            {overs.RUNS}
                          </span>
                        )}
                      </div>
                    </>
                  );
              })}
          </div>
        </div>
      </div>
    </>
  );
}
export default Ticker2;
