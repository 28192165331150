import React from "react";
import t2_teams_title_bg from "../../Assets/T2/batting_summary_title_bg.png";
import "../../Styles/T2/T2BattingSummary.css";
import t2_teams_bg from "../../Assets/T2/teams_white_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import { useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import qs from "qs";
import T2BowlingSummary from "./T2BowlingSummary";
import he from "he";
import t2_default_logo from "../../Assets/T2/t2_team_default_logo.png"

function T2BattingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");
  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    team_name: "",
    team_extras: "",
    team_overs: "",
    runs: "",
    wickets: "",
    team_nick_name: "",
    curr_innings: "",
  });
  const [playerDivId, setPlayerDivId] = useState("");
  const [playerNotOutId, setPlayerNotOutId] = useState("");
  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };

  const decodeNames = (x) => {
    // const [teamImage, setTeamImage] = useState({batting_team_logo})
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        player_names: responseData.BATTING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: he.decode(responseData.BATTING_TEAM_DETAILS.TEAM_NAME),
        team_logo: responseData.BATTING_TEAM_DETAILS.TEAM_URL,
        team_extras: responseData.BATTING_TEAM_DETAILS.EXTRAS,
        team_overs: responseData.BATTING_TEAM_DETAILS.OVERS,
        runs: responseData.BATTING_TEAM_DETAILS.RUNS,
        wickets: responseData.BATTING_TEAM_DETAILS.WICKETS,
        team_nick_name: responseData.BATTING_TEAM_DETAILS.TEAM_NICK_NAME,
        curr_innings: responseData.CURRENT_INNINGS,
      });
    });
  }, []);
  // useEffect(() => {
  // summary.player_names === undefined ? "":
  //   summary.player_names.map((players) => {
  //     console.log(players.OUT_TEXT);
  //     players.OUT_TEXT !== "NOT OUT"
  //       ? setPlayerDivId("t2_batting_player_name_bg")
  //       : setPlayerDivId("");
  //       players.OUT_TEXT !== "NOT OUT"
  //       ? setPlayerNotOutId("t2_batting_not_out_player_name_bg")
  //       :setPlayerNotOutId("");
  //     // (players.OUT_TEXT) === "NOT OUT"
  //     //   ? setPlayerNotOutId("t2_batting_not_out_player_name_bg")
  //     //   : "";
  //   });

  //   console.log(playerDivId);
  // });

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  return (
    <>
      {showTicker ? (
      <div className="d-flex position-relative" id="t2_batting_font">
        <div id="t2_batting_title_bg">
          <img src={t2_teams_title_bg} alt="team_players" width="100%" />
        </div>
        <div id="t2_batting_teama_logo">
          <img  src={process.env.REACT_APP_API_URL + summary.team_logo}
          alt=""
          onError={(e) => (e.target.src = t2_default_logo)} />
        </div>
        <div id="t2_batting_teama_short_name">
          {decodeNames(he.decode(summary.team_nick_name))}
        </div>
        <div id="t2_batting_teama_name">
          ({he.decode(decodeNames(summary.team_name))})
        </div>
        <div id="t2_batting_batting_summary">Batting summary</div>

        <div id="t2_white_batting_bg">
          <img src={t2_teams_bg} alt="t2_batting_bg" width="100%" />
          <div className="row " id="t2_batting_row">
            {" "}
            <div className="col-12">
              {summary.player_names === undefined
                ? ""
                : (() => {
                    const battingPlayer = summary.player_names
                      .filter((player) => player.RUNS !== "")
                      // .sort((a, b) => b.RUNS - a.RUNS)
                      .slice(0, 11); // First array gets up to 11 items

                    const nonBattingPlayers = summary.player_names
                      .filter((player) => player.RUNS === "")
                      .slice(0, Math.max(0, 11 - battingPlayer.length)); // Second array gets the remaining items

                    const combinedList = [
                      ...battingPlayer,
                      ...nonBattingPlayers,
                    ];

                    return combinedList.map((players, index) => {
                      return (
                        <div id="t2_batting_player_type_bg">
                          <div
                            className={
                              players.OUT_TEXT === "NOT OUT"
                                ? "t2_batting_not_out_player_name_bg"
                                : "t2_batting_player_name_bg"
                            }
                          >
                            <span id="t2_batting_player_name">
                              {decodeNames(he.decode(players.NAME))}
                            </span>
                            <span id="t2_batting_catcher_name">
                              {players.OUT_TEXT === "NOT OUT"
                                ? players.OUT_TEXT
                                : players.OUT_TEXT === "OUT"
                                ? players.HOW_OUT === "BOWLED"
                                  ? ""
                                  : players.HOW_OUT === "CAUGHT"
                                  ? "c " + players.FIELDER
                                  : players.HOW_OUT
                                : ""}
                            </span>
                            <span id="t2_batting_bowler_name">
                              {" "}
                              {players.HOW_OUT === "BOWLED"
                                ? "b " + decodeNames(players.BOWLER)
                                : players.HOW_OUT === "CAUGHT"
                                ? "b " + decodeNames(players.BOWLER)
                                : ""}
                            </span>
                            <span id="t2_batting_runs">{players.RUNS}</span>
                            <span id="t2_batting_balls">{players.BALLS}</span>
                          </div>
                        </div>
                      );
                    });
                  })()}
            </div>
          </div>
        </div>

        <div id="t2_batting_bottom_line">
          <div id="t2_batting_extras_overs_bg">
            <div id="t2_batting_extras">
              extras {summary.team_extras === null ? 0 : summary.team_extras}
            </div>
            <div id="t2_batting_overs">overs {summary.team_overs}</div>
          </div>

          <div id="t2_batting_result">
            {summary.runs}-{summary.wickets}
          </div>
        </div>
      </div>
    ) : (
        <T2BowlingSummary />
      )}
    </>
  );
}
export default T2BattingSummary;
