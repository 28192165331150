import React, { useState, useEffect, useContext } from "react";
import Lottie from "react-lottie";
import four_lottie from "../../Lottie/four_lottie.json";
import { useNavigate, useSearchParams } from "react-router-dom";
import { SocketContext } from "../../socket";

function T1Four(props) {
  const socket = useContext(SocketContext);

  const defaultOptions = {
    animationData: four_lottie,
    autplay: true,
    loop: false,
  };

  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  let navigate = useNavigate();
  // const [showTicker, setShowTicker] = useState(true);

  // useEffect(() => {
  //   setTimeout(function () {
  //     setShowTicker(!showTicker);
  //     // setShow("none");
  //   }, 6000);
  // }, []);
  // useEffect(() => {
  //   localStorage.setItem("landing_page", 0);
  // }, []);
  // const [canNavigate, setCanNavigate] = useState(true);

  // useEffect(() => {
  //   socket.on("connect", () => {
  //     console.log("four connects");
  //   });
  //   socket.on("four", (data) => {
  //     console.log(data, "daata");

  //     // Wait for six seconds before allowing navigation again
  //     setTimeout(() => {
  //       // Allow navigation

  //       // Navigate back to the first file
  //       navigate(`/t1?match_id=${match_id}`); // Replace with the actual URL
  //     }, 6000); // 6 seconds
  //   });

  //   // Clean up the socket listener when the component unmounts
  //   return () => {
  //     socket.off("four");
  //   };
  // }, []);

  // useEffect(() => {
  //   setTimeout(function () {
  //     navigate(`/t1?match_id=8811`);
  //   }, 6000);
  // },[]);
  return (
    <>
      {/* {showTicker ? ( */}
      <div className="animateT1Four">
        <Lottie
          options={defaultOptions}
          height="100vh"
          width="100vh"
          style={{ backgroundSize: "cover", height: "100vh", width: "100vw" }}
        />
      </div>
      {/* ) : (
       window.history.back()
      )} */}
    </>
  );
}
export default T1Four;
