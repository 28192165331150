import React, { useState, useEffect } from "react";
import t2_teams_title_bg from "../../Assets/T2/team_playing_titile_bg.png";
import "../../Styles/T2/T2MatchSummary.css";
import t2_teams_bg from "../../Assets/T2/teams_white_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import T2SummaryBottom from "./T2SummaryBottom";
import he from "he";
import team_default_logo from "../../Assets/T2/t2_team_default_logo.png";

function T2MatchSummary() {
  let [searchParams] = useSearchParams();

  let match_id = searchParams.get("match_id");
  const [summary, setSummary] = useState({
    teama_batting_players: [],
    teama_bowled_players: [],
    teama_name: "",
    teama_runs: "",
    teama_overs: "",
    teama_wickets: "",
    teamb_batting_players: [],
    teamb_name: "",
    teamb_runs: "",
    teamb_overs: "",
    teamb_wickets: "",
    teamb_bowled_players: [],
    result: "",
    teama_short_name: "",
    teamb_short_name: "",
  });

  const reqData = {
    mod: "MatchScore",
    actionType: "get-match-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        teama_batting_players: responseData.TEAM_A.BATTING,
        teama_bowled_players: responseData.TEAM_A.BOWLING,
        teama_name: responseData.TEAM_A.TEAM_NAME,
        teama_runs: responseData.TEAM_A.RUNS,
        teama_wickets: responseData.TEAM_A.WICKETS,
        teama_overs: responseData.TEAM_A.OVERS,
        teamb_name: responseData.TEAM_B.TEAM_NAME,
        teamb_batting_players: responseData.TEAM_B.BATTING,
        teamb_bowled_players: responseData.TEAM_B.BOWLING,
        teamb_runs: responseData.TEAM_B.RUNS,
        teamb_wickets: responseData.TEAM_B.WICKETS,
        teamb_overs: responseData.TEAM_B.OVER,
        result: responseData.RESULT,
        teama_short_name: responseData.TEAM_A.TEAM_NICK_NAME,
        teamb_short_name: responseData.TEAM_B.TEAM_NICK_NAME,
      });
    });
  }, [0]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const maxLetterCount = 20; // Set the maximum letter count

  const striker = document.getElementById("t2_match_teama_name");
  if (striker) {
    if (striker.textContent.length > maxLetterCount) {
      const truncatedText = striker.textContent.substring(0, maxLetterCount);
      striker.textContent = truncatedText + "...";
    }
  }
  const spec_team_name = document.getElementById("t2_match_teama_spec_name");
  if (spec_team_name) {
    if (spec_team_name.textContent.length > maxLetterCount) {
      const truncatedText = spec_team_name.textContent.substring(
        0,
        maxLetterCount
      );
      spec_team_name.textContent = truncatedText + "...";
    }
  }

  return (
    <>
      {showTicker ? (
        <div className="d-flex position-relative" id="t2_match_font">
          <div id="t2_match_title_bg">
            <img src={t2_teams_title_bg} alt="team_players" width="100%" />
          </div>
          <div id="t2_match_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + summary.teamb_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="t2_teama_logo"
            />
          </div>
          <div id="t2_match_teama_short_name">
            {decodeNames(he.decode(summary.teama_short_name))}
          </div>
          <div id="t2_match_teama_name">
            ({decodeNames(he.decode(summary.teama_name))})
          </div>
          <div id="t2_match_playing_text">Match summary</div>
          <div id="t2_match_teamb_logo">
            <img
              src={process.env.REACT_APP_API_URL + summary.teamb_logo}
              onError={(e) => (e.target.src = team_default_logo)}
              alt="t2_teama_logo"
            />
          </div>
          <div id="t2_match_teamb_short_name">
            {decodeNames(he.decode(summary.teamb_short_name))}
          </div>
          <div id="t2_match_teamb_name">
            ({decodeNames(he.decode(summary.teamb_short_name))})
          </div>

          <div id="t2_white_match_bg">
            <img src={t2_teams_bg} alt="t2_match_bg" width="100%" />
            <div className="row " id="t2_match_row">
              {" "}
              <div className="col-6 " id="t2_match_teama_names">
                <div id="t2_match_teama_short_spec_name">
                  {decodeNames(he.decode(summary.teama_short_name))}
                </div>
                <div id="t2_match_teama_spec_name" className="ps-2">
                  ({decodeNames(he.decode(summary.teama_name))})
                </div>
                <div id="t2_match_teama_runs_balls">
                  {summary.teama_runs}-{summary.teama_wickets}
                </div>
                <div id="t2_match_teama_overs">overs {summary.teama_overs}</div>
                {summary.teama_batting_players == undefined
                  ? ""
                  : summary.teama_batting_players.map((batting_players) => {
                      return (
                        <div id="t2_match_player_type_bg">
                          <span id="t2_match_player_type">
                            <span id="t2_match_player_runs">
                              {batting_players.RUNS}
                            </span>{" "}
                            <span id="t2_match_player_balls">
                              {batting_players.BALLS}
                            </span>
                          </span>
                          <div id="t2_match_player_name_bg">
                            <span id="t2_match_player_name">
                              {decodeNames(
                                he.decode(batting_players.FULL_NAME)
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="col-6" id="t2_match_teamb_names">
                {summary.teamb_bowled_players === undefined
                  ? ""
                  : summary.teamb_bowled_players.map((bowling_players) => {
                      const overs = parseFloat(bowling_players.OVERS);
                      const formattedOvers =
                        isNaN(overs) || !Number.isInteger(overs)
                          ? overs.toFixed(1)
                          : overs;
                      return (
                        <div id="t2_match_player_type_bg">
                          <span id="t2_match_player_type">
                            {bowling_players.WICKETS} - {bowling_players.RUNS}
                            <span id="t2_match_player_bowling_overs">
                              {/* {bowling_players.OVERS} */}
                              {formattedOvers}
                            </span>
                          </span>

                          <div id="t2_match_player_name_bg">
                            <span id="t2_match_player_name">
                              {decodeNames(
                                he.decode(bowling_players.FULL_NAME)
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className="row " id="t2_match_teamb_row">
              {" "}
              <div className="col-6 " id="t2_match_teama_names">
                <div id="t2_match_teama_short_spec_name">
                  {decodeNames(he.decode(summary.teamb_short_name))}
                </div>
                <div id="t2_match_teama_spec_name" className="ps-2">
                  ({decodeNames(he.decode(summary.teamb_name))})
                </div>
                <div id="t2_match_teama_runs_balls">
                  {summary.teamb_runs}-{summary.teamb_wickets}
                </div>
                <div id="t2_match_teama_overs">overs {summary.teamb_overs}</div>
                {summary.teamb_batting_players === undefined
                  ? ""
                  : summary.teamb_batting_players.map((batting_players) => {
                      return (
                        <div id="t2_match_player_type_bg">
                          <span id="t2_match_player_type">
                            {batting_players.RUNS}
                            <span id="t2_match_player_balls">
                              {batting_players.BALLS}
                            </span>
                          </span>
                          <div id="t2_match_player_name_bg">
                            <span id="t2_match_player_name">
                              {he.decode(
                                decodeNames(batting_players.FULL_NAME)
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="col-6" id="t2_match_teamb_names">
                {summary.teama_bowled_players === undefined
                  ? ""
                  : summary.teama_bowled_players.map((bowling_players) => {
                      const overs = parseFloat(bowling_players.OVERS);
                      const formattedOvers =
                        isNaN(overs) || !Number.isInteger(overs)
                          ? overs.toFixed(1)
                          : overs;
                      return (
                        <div id="t2_match_player_type_bg">
                          <span id="t2_match_player_type">
                            {bowling_players.WICKETS}-{bowling_players.RUNS}
                            <span id="t2_match_player_bowling_overs">
                              {/* {bowling_players.OVERS} */}
                              {formattedOvers}
                            </span>
                          </span>
                          <div id="t2_match_player_name_bg">
                            <span id="t2_match_player_name">
                              {he.decode(
                                decodeNames(bowling_players.FULL_NAME)
                              )}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          <div id="t2_match_bottom_line">
            {he.decode(decodeNames(summary.result))}
          </div>
        </div>
      ) : (
        <T2SummaryBottom />
      )}
    </>
  );
}
export default T2MatchSummary;
