import React from "react";
// import { Lottie } from "react-lottie";
import four_lottie from "../../Lottie/four_lottie.json";
import test_lottie from "../../Lottie/test_lottie.json";
import bg_lottie from "../../Lottie/bg_lottie.json";
import Lottie from "react-lottie";
import ticker_animation from "../../Lottie/ticker_animation.json";
import { LottiePlayer } from "lottie-react";
const animationWidth = 1920; // Replace with your animation's width
const animationHeight = 1080; // Replace with your animation's height

const screenWidth = window.innerWidth; // Current viewport width
const screenHeight = window.innerHeight; // Current viewport height

const scaleX = screenWidth / animationWidth;
const scaleY = screenHeight / animationHeight;

const scaleFactor = Math.min(scaleX, scaleY);
const lottieOptions = {
  loop: true,
  autoplay: true,
  animationData: test_lottie, // Replace with the correct animation data import
  rendererSettings: {
    scale: scaleFactor, // Apply the calculated scale factor
  },
};

const getAnimationSize = () => {
  const viewportWidth = window.innerWidth;
  const animationWidth = 1920; // Replace with your animation's width
  const scaleFactor = viewportWidth / animationWidth;
  const animationHeight = 1080 * scaleFactor; // Replace with your animation's height

  return {
    width: viewportWidth,
    height: animationHeight,
  };
};

const animationSize = getAnimationSize();

function Lottiefour() {
  var Webflow = Webflow || [];
  Webflow.push(function () {
    window.addEventListener("resize", function () {
      window.Webflow.require("lottie").lottie.resize();
    });
  });

  return (
    <div
      className="position-relative"
      style={{
        position: "relative",
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <div
      //   style={{
      //     position: 'absolute',
      //     top: '50%',
      //     left: '50%',
      //     transform: 'translate(-50%, -50%)',
      //     width: animationSize.width,
      //     height: animationSize.height,
      //   }}
      >
        <Lottie
          style={{
            objectFit: "cover",
            position: "absolute",
          }}
          options={{
            loop: false,
            animationData: four_lottie,
          }}
        />
      </div>
    </div>
    // <div

    // >
    //   <lottie-player
    //     src="https://lottie.host/5c536bf8-8fa7-43be-b9ba-064b7db44ce1/TJzirGU4RI.json"
    //     background="transparent"
    //     speed="1"
    //     autoplay

    //   ></lottie-player>
    // </div>
  );
}

export default Lottiefour;
