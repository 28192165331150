import React, { useState, useEffect } from "react";
import scorecardbg from "../../Assets/scorecard_bg.png";
import batting_team_logo from "../../Assets/batting_team_logo.png";
import "../../Styles/T1/BeforeStart.css";
import { useSearchParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import team_deault_img from "../../Assets/team_logo.png";
import he from "he";
function BeforeStart() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_short_name: "",
    teamb_logo: "",
    result: "",
    total_over: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      // console.log(data);s

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        total_over: mainData.TOTAL_OVER,
        result: mainData.RESULT,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);
  const [teamaLogo, setTeamaLogo] = useState(
    process.env.REACT_APP_API_URL + teamName.teama_logo
  );
  const handleTeamLogo = () => {
    setTeamaLogo(team_deault_img);
  };
  const [teambLogo, setTeambLogo] = useState(
    process.env.REACT_APP_API_URL + teamName.teamb_logo
  );
  const handleTeambLogo = () => {
    setTeambLogo(team_deault_img);
  };

  const handleOnError = (e) => {
    e.target.src = team_deault_img;
  };
  const decodeNames = (x) => {
    // const [teamImage, setTeamImage] = useState({batting_team_logo})
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  return (
    <div
      className="position-relative d-flex"
      style={{
        // backgroundImage: `url(${scorecardbg})`,
        height: "100vh",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      {/* {window.location.reload(true)} */}
      <div id="teama_logo_bg">
        <span
          id="teama_logo_outer_gradient"
          className="position-absolute  z-99 "
        >
          <div id="teama_logo_inner_gradient">
            <div id="teama_logo" className="d-flex justify-content-center">
              {}

              <img
                // {...props}
                // className={`defaultClass ${className}`}
                src={process.env.REACT_APP_API_URL + teamName.teama_logo}
                width="100%"
                style={{ borderRadius: "2.6vw", height: "7vh", width: "7vh" }}
                alt="jh"
                onError={handleOnError}
              />

              {/* <img
                src={teamaLogo}
                alt="logo"
                width="100%"
                onError={handleTeamLogo}
              ></img> */}
            </div>
          </div>
        </span>{" "}
      </div>
      <div id="teamb_logo_bg">
        <span
          id="teamb_logo_outer_gradient"
          className="position-absolute  z-99 "
        >
          <div id="teamb_logo_inner_gradient">
            <div id="teamb_logo" className="d-flex justify-content-center">
              <img
                src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
                alt="logo"
                style={{ borderRadius: "2.6vw", height: "7vh", width: "7vh" }}
                width="100%"
                onError={handleOnError}
              ></img>
            </div>
          </div>
        </span>{" "}
      </div>

      <div className="position-absolute bottom-0 start-0 d-flex flex-column ">
        <div
          id="green_layer"
          className="d-flex justify-content-center align-items-center"
        >
          <div className=" ">
            <div className="" id="start_teama_short_name">
              {he.decode(decodeNames(teamName.teama_short_name))}
            </div>{" "}
            <span id="start_teama_full_name" className="px-1">
              ( {he.decode(decodeNames(teamName.teama_name))} ) 
            </span>
          </div>
          <div id="before_start_vs_text_bg" className="">
            <span id="before_start_vs_text">VS</span>
          </div>
          <div className=" ">
            <div id="start_teamb_short_name">
              {he.decode(decodeNames(teamName.teamb_short_name))}
            </div>{" "}
            <span id="start_teamb_full_name" className="px-1">
              ( {he.decode(decodeNames(teamName.teamb_name))} ) 
            </span>
          </div>
        </div>
        <div
          id="blue_layer"
          className="d-flex align-items-center justify-content-center text-white"
        >
          {teamName.result} MATCH WILL START SOON - {teamName.total_over} OVERS
          MATCH
        </div>
      </div>
    </div>
  );
}
export default BeforeStart;
