import React, { useState, useEffect, useContext } from "react";
import t2_teams_title_bg from "../../Assets/T2/team_playing_titile_bg.png";
import "../../Styles/T2/T2Teams.css";
import t2_teams_bg from "../../Assets/T2/teams_white_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import t2_teamb_logo from "../../Assets/T2/t2_teamb_logo.png";
import he from "he";
import t2_default_logo from "../../Assets/T2/t2_team_default_logo.png";

import { SocketContext } from "../../socket";
import { useNavigate, useSearchParams } from "react-router-dom";
import T2TossBottom from "./TossBottom";
function T2Teams() {
  const socket = useContext(SocketContext);
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  const navigate = useNavigate();

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const [showTicker, setShowTicker] = useState(true);
  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
    }, 6000);
  }, []);
  const maxLetterCount = 21; // Set the maximum letter count

  const team_a_name = document.getElementById("t2_teams_teama_name");
  if (team_a_name) {
    if (team_a_name.textContent.length > maxLetterCount) {
      const truncatedText = team_a_name.textContent.substring(
        0,
        maxLetterCount
      );
      team_a_name.textContent = truncatedText + "...";
    }
  }

  const team_b_name = document.getElementById("t2_teams_teamb_name");
  if (team_b_name) {
    if (team_b_name.textContent.length > maxLetterCount) {
      const truncatedText = team_b_name.textContent.substring(
        0,
        maxLetterCount
      );
      team_b_name.textContent = truncatedText + "...";
    }
  }
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    team_a_players: [],
    team_b_players: [],
    teama_squad_type: [],
    teamb_squad_type: [],
  });

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected");
    });

    socket.on("live_score_new", (data) => {
      console.log(data);
      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        team_a_players: mainData.TEAM_A.TEAM_SQUAD,
        team_b_players: mainData.TEAM_B.TEAM_SQUAD,
      });
    });
    socket.emit("join", "match_id_", match_id);
    socket.emit("get_score", match_id);

    // { scores.status === "COMPLETED"
    //     ? navigate(`/match-summary? ${match_id}`)
    //     : ""}
  }, [0]);

  return (
    <>
      {showTicker ? (
        <div className="d-flex position-relative" id="t2_teams_font">
          <div id="t2_teams_title_bg">
            <img src={t2_teams_title_bg} alt="team_players" width="100%" />
          </div>
          <div id="t2_teams_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt="t2_teama_logo"
              onError={(e) => (e.target.src = t2_default_logo)}
            />
            {/* <img src={t2_teamb_logo} alt="t2_teama_logo" /> */}
          </div>
          <div id="t2_teams_teama_short_name">
            {decodeNames(he.decode(teamName.teama_short_name))}
          </div>
          <div id="t2_teams_teama_name">
            ({decodeNames(he.decode(teamName.teama_name))})
          </div>
          <div id="t2_teams_playing_text">teams playing</div>
          <div id="t2_teams_teamb_logo">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              onError={(e) => (e.target.src = t2_default_logo)}
              alt="t2_teama_logo"
            />
          </div>
          <div id="t2_teams_teamb_short_name">
            {he.decode(decodeNames(teamName.teamb_short_name))}
          </div>
          <div id="t2_teams_teamb_name">({he.decode(teamName.teamb_name)})</div>

          <div id="t2_white_teams_bg">
            <img src={t2_teams_bg} alt="t2_teams_bg" width="100%" />
            <div className="row " id="t2_teams_row">
              {" "}
              <div className="col-6 ">
                {teamName.team_a_players.slice(0, 11).map((teama_players) => {
                  return (
                    <div id="t2_teams_player_type_bg" className="d-flex">
                      <span id="t2_teams_player_type">
                        {teama_players.SQUAD_TYPE}
                      </span>
                      <div id="t2_teams_player_name_bg">
                        <span id="t2_teams_player_name">
                          {decodeNames(he.decode(teama_players.FULL_NAME))}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="col-6" id="t2_teams_teams_teamb_names">
                {teamName.team_b_players.slice(0, 11).map((teamb_players) => {
                  return (
                    <div id="t2_teams_player_type_bg" className="d-flex">
                      <span id="t2_teams_player_type">
                        {teamb_players.SQUAD_TYPE}
                      </span>
                      <div id="t2_teams_player_name_bg">
                        <span id="t2_teams_player_name">
                          {decodeNames(teamb_players.FULL_NAME)}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div id="t2_teams_bottom_line"></div>
        </div>
      ) : (
        <T2TossBottom />
      )}
    </>
  );
}
export default T2Teams;
