import React, { useState, useEffect } from "react";
import out_lottie from "../../Lottie/t1_out_animation.json";
import Lottie from "react-lottie";
import { useNavigate, useSearchParams } from "react-router-dom";
function T1Out() {
  const defaultOptions = {
    animationData: out_lottie,
    autoplay: true,
    loop: false,
  };
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  let navigate = useNavigate();
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  return (
    <>
      {showTicker ? (
        <div>
          <Lottie options={defaultOptions} />
        </div>
      ) : (
        navigate(`/t1?match_id=${match_id}`)
      )}
    </>
  );
}
export default T1Out;
