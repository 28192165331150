import React from "react";
import "../../Styles/T2/T2BeforeStart.css";
import before_start_bg from "../../Assets/T2/before_start_bg.png";
// import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
// import t2_teamb_logo from "../../Assets/T2/t2_teamb_logo.png";
import socketIOClient from "socket.io-client";
import { useSearchParams } from "react-router-dom";
// import team_deault_img from "../../Assets/team_logo.png";
import { useState, useEffect } from "react";
import t2_team_default_logo from "../../Assets/T2/t2_team_default_logo.png";
import he from "he";
function T2TossBottom() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    result: "",
    teama_short_name: "",
    teamb_short_name: "",
    total_over: "",
    toss_won_team: "",
    toss_decision: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        total_over: mainData.TOTAL_OVER,
        result: mainData.RESULT,
        toss_won_team: he.decode(data.TOSS.TOSS_WON_TEAM),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
      });
      console.log(process.env.REACT_APP_API_URL + teamName.teamb_logo, "ss");
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);

  const [teamLogo, setTeamLogo] = useState(
    process.env.REACT_APP_API_URL + teamName.teamb_logo
  );

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  return (
    <div className="position-relative d-flex" id="before_start_container">
      {/* <h1>hj</h1> */}
      <div
        id="t2_bottom_layer"
        className="d-flex justify-content-center align-items-center"
      >
        {" "}
        TOSS WON BY {teamName.toss_won_team} AND CHOOSE TO {teamName.toss_decision}
      </div>
      <div id="t2_teams_bg">
        <img src={before_start_bg} alt="before_start_bg" width="100%" />
      </div>
      <div id="t2_start_teama_logo">
        <img
          src={process.env.REACT_APP_API_URL + teamName.teama_logo}
          alt=""
          onError={(e) => (e.target.src = t2_team_default_logo)}
        />
      </div>
      <div id="t2_start_teama_short_name">
        {decodeNames(he.decode(teamName.teama_short_name))}
      </div>
      <div id="t2_start_teama_name">
        ({decodeNames(he.decode(teamName.teama_name))})
      </div>
      <div id="t2_start_vs_text">VS</div>
      <div id="t2_start_teamb_short_name">
        {decodeNames(he.decode(teamName.teamb_short_name))}
      </div>
      <div id="t2_start_teamb_name">
        ({decodeNames(he.decode(teamName.teamb_name))})
      </div>
      <div id="t2_start_teamb_logo">
        <img
          src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
          alt="team2_logo"
          onError={(e) => (e.target.src = t2_team_default_logo)}
        />
      </div>
    </div>
  );
}
export default T2TossBottom;
