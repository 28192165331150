import React, { useEffect, useState } from "react";
// import score_card_bg from "../../Assets/scorecard_bg.png";
// import bowling_summary_effect from "../../Assets/batting_summary_effect.png";
// import teams_gradient_effect from "../../Assets/team_gradient_effect.png";
import "../../Styles/T1/MatchSummary.css";
import qs from "qs";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import BattingSummary from "./BattingSummary";
import T1SummaryBottom from "./T1SummaryBottom";
import he from "he";
// import Player from "video.js/dist/types/player";

function MatchSummary() {
  let [searchParams] = useSearchParams();
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  let match_id = searchParams.get("match_id");
  const [summary, setSummary] = useState({
    teama_batting_players: [],
    teama_bowled_players: [],
    teama_name: "",
    teama_runs: "",
    teama_overs: "",
    teama_wickets: "",
    teamb_batting_players: [],
    teamb_name: "",
    teamb_runs: "",
    teamb_overs: "",
    teamb_wickets: "",
    teamb_bowled_players: [],
    result: "",
    teama_short_name: "",
    teamb_short_name: "",
  });

  const reqData = {
    mod: "MatchScore",
    actionType: "get-match-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "sres");
      let responseData = res.data.XSCData;
      setSummary({
        teama_batting_players: responseData.TEAM_A.BATTING,
        teama_bowled_players: responseData.TEAM_A.BOWLING,
        teama_name: he.decode(responseData.TEAM_A.TEAM_NAME),
        teama_runs: responseData.TEAM_A.RUNS,
        teama_wickets: responseData.TEAM_A.WICKETS,
        teama_overs: responseData.TEAM_A.OVERS,
        teamb_name: he.decode(responseData.TEAM_B.TEAM_NAME),
        teamb_batting_players: responseData.TEAM_B.BATTING,
        teamb_bowled_players: responseData.TEAM_B.BOWLING,
        teamb_runs: responseData.TEAM_B.RUNS,
        teamb_wickets: responseData.TEAM_B.WICKETS,
        teamb_overs: responseData.TEAM_B.OVER,
        result: responseData.RESULT,
        teama_short_name: he.decode(responseData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(responseData.TEAM_B.TEAM_NICK_NAME),
      });
    });
  }, [0]);

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  function formatNumber(number) {
    // Check if the number is not a number and try to convert it
    if (isNaN(number)) {
      number = parseFloat(number);
    }

    if (Number.isInteger(number)) {
      return number.toString(); // Convert to a string without decimal places for whole numbers
    } else {
      return number.toFixed(1); // Round to one decimal place for numbers with decimals
    }
  }
  const maxLetterCount = 24; // Set the maximum letter count

  const bowling_team_name = document.getElementById("bowling_summary_team_name");
  if (bowling_team_name) {
    if (bowling_team_name.textContent.length > maxLetterCount) {
      const truncatedText = bowling_team_name.textContent.substring(0, maxLetterCount);
      bowling_team_name.textContent = truncatedText + "...";
    }
  }
  return (
    <>
      {showTicker ? (
        <div
          data-aos="zoom-in"
          data-aos-delay="500"
          className="d-flex flex-column position-relative"
          style={{
            // backgroundImage: `url(${score_card_bg})`,
            height: "100vh",
            backgroundRepeat: "no-repeat",
          }}
        >
          <div id="match_summary_bg_white" className="m-auto position-relative">
            <div
              id="match_summary_text"
              className="position-absolute top-0 translate-middle d-flex align-items-center"
            >
              match summary
            </div>

            <div className="d-flex">
              <div
                id="teama_summary_bg"
                className="d-flex align-items-center justify-content-between"
              >
                <div className="d-flex align-items-center ">
                  <div className="" id="summary_teama_short_name">
                    {he.decode(decodeNames(summary.teama_short_name))}
                  </div>
                  <div id="summary_teama_full_name" className="px-3">
                    {he.decode(decodeNames(summary.teama_name))}
                  </div>
                </div>

                <div
                  id="teama_summary_runs"
                  align="end"
                  className="d-flex align-items-center "
                >
                  {/* <div className="">450-10</div> */}
                  {summary.teama_runs}-{summary.teama_wickets}
                  <sub id="teama_summary_overs" className="">
                    Overs {summary.teama_overs}
                  </sub>
                </div>
              </div>
            </div>
            <div className="row " id="summary_row_width">
              <div className="col-6 ">
                {summary.teama_batting_players === 0
                  ? ""
                  : summary.teama_batting_players.map((battingPlayers) => {
                      return (
                        <div className="d-flex  align-items-center">
                          <div
                            id="teama_summary_player_name"
                            className="d-flex align-items-center "
                          >
                            {" "}
                            <span id="player_text_width">
                              {decodeNames(battingPlayers.FULL_NAME)}
                            </span>
                          </div>
                          <div
                            id="teama_summary_player_runs"
                            className="d-flex align-items-center justify-content-center "
                          >
                            {battingPlayers.RUNS}
                            <span id="teama_summary_player_balls">
                              {battingPlayers.BALLS}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="col-6 px-3 ">
                {summary.teamb_bowled_players === 0
                  ? ""
                  : summary.teamb_bowled_players.map((bowledPlayers) => {
                      const overs = parseFloat(bowledPlayers.OVERS);
                      const formattedOvers =
                        isNaN(overs) || !Number.isInteger(overs)
                          ? overs.toFixed(1)
                          : overs;
                      return (
                        <div className="d-flex  align-items-center">
                          <div
                            id="teama_summary_player_name"
                            className="d-flex align-items-center "
                          >
                            {" "}
                            <span id="player_text_width">
                              {" "}
                              {decodeNames(bowledPlayers.FULL_NAME)}
                            </span>
                          </div>
                          <div
                            id="teama_summary_player_runs"
                            className="d-flex align-items-center justify-content-center "
                          >
                            {bowledPlayers.WICKETS}-{bowledPlayers.RUNS}
                            <span id="teama_summary_player_balls">
                              {formattedOvers}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
            <div className=" d-flex">
              <div
                id="teamb_summary_bg"
                className="d-flex align-items-center justify-content-between"
              >
                <div className="d-flex align-items-center ">
                  <div className="" id="summary_teama_short_name">
                    {(he.decode(decodeNames(summary.teamb_short_name)))}
                  </div>
                  <div id="summary_teama_full_name" className="px-3">
                    {(he.decode(decodeNames(summary.teamb_name)))}
                  </div>
                </div>

                <div
                  id="teama_summary_runs"
                  align="end"
                  className="d-flex align-items-center "
                >
                  <div className="">
                    {summary.teamb_runs}-{summary.teamb_wickets}
                  </div>
                  <sub id="teama_summary_overs" className="">
                    Overs {summary.teamb_overs}
                  </sub>
                </div>
              </div>
            </div>
            <div className="row " id="summary_row_width">
              <div className="col-6 ">
                {summary.teamb_batting_players === 0
                  ? ""
                  : summary.teamb_batting_players.map((bowledPlayers) => {
                      return (
                        <div className="d-flex  align-items-center">
                          <div
                            id="teama_summary_player_name"
                            className="d-flex align-items-center "
                          >
                            {" "}
                            <span id="player_text_width">
                              {decodeNames(bowledPlayers.FULL_NAME)}
                            </span>
                          </div>
                          <div
                            id="teama_summary_player_runs"
                            className="d-flex align-items-center justify-content-center "
                          >
                            <span
                              style={{
                                display: "flex",
                                justifyContent: "left",
                              }}
                            ></span>{" "}
                            {bowledPlayers.RUNS}
                            <span
                              id="teama_summary_player_balls"
                              style={{
                                display: "flex",
                                justifyContent: "left",
                                textAlign: "left",
                              }}
                            >
                              {bowledPlayers.BALLS}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
              <div className="col-6 px-3 ">
                {summary.teama_bowled_players === 0
                  ? ""
                  : summary.teama_bowled_players.map((bowledPlayers) => {
                      const overs = parseFloat(bowledPlayers.OVERS);
                      const formattedOvers =
                        isNaN(overs) || !Number.isInteger(overs)
                          ? overs.toFixed(1)
                          : overs;
                      return (
                        <div className="d-flex  align-items-center">
                          <div
                            id="teama_summary_player_name"
                            className="d-flex align-items-center "
                          >
                            <span id="player_text_width">
                              {" "}
                              {decodeNames(bowledPlayers.FULL_NAME)}
                            </span>{" "}
                          </div>
                          <div
                            id="teama_summary_player_runs"
                            className="d-flex align-items-center justify-content-center "
                          >
                            {bowledPlayers.WICKETS}-{bowledPlayers.RUNS}
                            <span id="teama_summary_player_balls">
                              {/* {(bowledPlayers.OVERS)} */}
                              {formattedOvers}
                            </span>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>

          <div
            id="bottom_result_text"
            className="m-auto d-flex align-items-center justify-content-center text-white"
          >
            {he.decode(he.decode(decodeNames(summary.result)))}
          </div>

          <div id="summary_left_outer_gradient_layout"></div>
          <div
            id="summary_left_inner_gradient_layout"
            className="position-absolute"
          ></div>
          <div
            id="summary_right_outer_gradient_layout"
            className="position-absolute "
          ></div>
          <div
            id="summary_right_inner_gradient_layout"
            className="position-absolute"
          ></div>
        </div>
      ) : (
        <T1SummaryBottom />
      )}
    </>
  );
}
export default MatchSummary;
