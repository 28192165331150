import React, { useState, useEffect, useContext } from "react";
import "../../Styles/T1/Ticker.css";
import { SocketContext } from "../../socket";
import { useSearchParams } from "react-router-dom";
import BattingSummary from "./BattingSummary";
import TossInfo from "./TossInfo";
import BeforeStart from "./BeforeStart";
import T1Four from "./T1Four";
import T1Six from "./T1Six";
import T1Out from "./T1Out";
import PlayerInfo from "./PlayerInfo";
import NonStrikerPlayerInfo from "./NonStrikerPlayerInfo";
import SuperOverTossBottom from "./SuperOverBottom";
import he from "he";

function Ticker() {
  const socket = useContext(SocketContext);

  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  const [showTicker, setShowTicker] = useState();

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
    }, 6000);
  }, []);
  const [showMatchSummary, setShowMatchSummary] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowMatchSummary(!showMatchSummary);
    }, 10000);
  }, []);
  const [showPlayerInfo, setShowPlayerInfo] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setShowPlayerInfo(!showPlayerInfo);
    }, 5000);
  }, []);

  // const he = require('he'); // Import the 'he' library

  const [scores, setScores] = useState({
    teamA_name: "",
    teamB_name: "",
    striker_name: "",
    striker_runs: "",
    striker_balls: "",
    curr_runs: "",
    wickets: "",
    overs: "",
    non_striker_id: "",
    bowler_name: "",
    bowler_wickets: "",
    bowler_runs: "",
    bowler_overs: "",
    // last_ball: "",
    toss_detail: "",
    result: "",
    current_inning: "",
    information: "",
    toss_decision: "",
    striker: "",
    non_striker: "",
    status: "",
    player_id: "",
    teamA_short_name: "",
    teamB_short_name: "",
    curr_overs: [],
    extras_type: "",
    bats_man: [],
    partnership: "",
    crr: "",
    rrr: "",
    extras: "",
    total_four: "",
    total_six: "",
    bowler: "",
    match_type: "",
    curr_match_id: "",
  });
  const [lastBall, setLastBall] = useState({});

  // const [IsDiv1Visible, setIsDiv1Visible] = useState("")

  const [ballText, setBallText] = useState(); // Initialize the state with an empty string
  // const imageUrl = {image}; // Replace with your image URL
  const timeoutSecs = 5; // Replace with your desired timeout in seconds

  // Function to update ballText and clear it after the specified timeout

  const updateBallText = () => {
    socket.on("four", () => {
      const newBallText = <T1Four />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
    socket.on("six", () => {
      const newBallText = <T1Six />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
    socket.on("wicket", ({ currentBall }) => {
      // console.log(currentBall, "out");

      const newBallText = <T1Out />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
  };
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  useEffect(() => {
    updateBallText();
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected!");
    });
    socket.on("live_score_new", (data) => {
      console.log(data, "kilsl");
      setScores({
        curr_match_id: data.MATCH_ID,
        wickets: data.BATTING.WICKETS,
        overs: data.BATTING.OVERS,
        bowler_name: data.BOWLER.NAME,
        bowler_runs: data.BOWLER.RUNS,
        bowler_wickets: data.BOWLER.WICKETS,
        bowler_overs: data.BOWLER.OVERS,
        curr_overs: data.CURRENT_OVER,
        curr_runs: data.BATTING.RUNS,
        teamA_name: he.decode(data.TEAM_A.TEAM_A_NAME),
        teamB_name: he.decode(data.TEAM_B.TEAM_B_NAME),
        result: data.RESULT,
        last_ball: data.LAST_BALL,
        toss_detail: data.RESULT,
        information: data.INFORMATION,
        toss_decision: data.TOSS.TOSS_DECISION,
        striker: data.STRIKER,
        non_striker: data.NONSTRIKER,
        current_inning: data.CURRENT_INNING,
        status: data.STATUS,
        player_id: data.STRIKER.PLAYER_ID,
        teamA_short_name: he.decode(data.TEAM_A.TEAM_NICK_NAME),
        teamB_short_name: he.decode(data.TEAM_B.TEAM_NICK_NAME),
        bats_man: data.BATSMAN,
        partnership: data.PARTNERSHIP,
        crr: data.BATTING.CRR,
        extras: data.BATTING.EXTRAS,
        rrr: data.BATTING.RRR,
        total_four: data.BATTING.TOTAL_FOUR,
        total_six: data.BATTING.TOTAL_SIX,
        bowler: data.BOWLER,
        match_type: data.TYPE,
      });
    });
    socket.emit("join", "match_id_", match_id);
    socket.emit("get_score", match_id);
  }, []);

  // console.log(value1, value2);
  const maxLetterCount = 11; // Set the maximum letter count

  const striker = document.getElementById("t1_player1_text");
  if (striker) {
    if (striker.textContent.length > maxLetterCount) {
      const truncatedText = striker.textContent.substring(0, maxLetterCount);
      striker.textContent = truncatedText + "...";
    }
  }

  const nonStriker = document.getElementById("t1_player2_name");

  if (nonStriker) {
    if (nonStriker.textContent.length > maxLetterCount) {
      const truncatedText = nonStriker.textContent.substring(0, maxLetterCount);
      nonStriker.textContent = truncatedText + "...";
    }
  }
  const bowlerMaxCount = 9;
  const bowler = document.getElementById("t1_bowler");

  if (bowler) {
    if (bowler.textContent.length > bowlerMaxCount) {
      const truncatedText = bowler.textContent.substring(0, bowlerMaxCount);
      bowler.textContent = truncatedText + "...";
    }
  }
  const partner1 = document.getElementById("partner1_name");

  if (partner1) {
    if (partner1.textContent.length > maxLetterCount) {
      const truncatedText = partner1.textContent.substring(0, maxLetterCount);
      partner1.textContent = truncatedText + "...";
    }
  }
  const partner2 = document.getElementById("partner2_name");

  if (partner2) {
    if (partner2.textContent.length > maxLetterCount) {
      const truncatedText = partner2.textContent.substring(0, maxLetterCount);
      partner2.textContent = truncatedText + "...";
    }
  }

  const [showDiv1, setShowDiv1] = useState(true);
  const [showDiv2, setShowDiv2] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShowDiv1((prevShowDiv1) => !prevShowDiv1);
      setShowDiv2((prevShowDiv2) => !prevShowDiv2);
    }, 8000); // Toggle every 10 seconds (10000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [activeDiv, setActiveDiv] = useState(0);
  const totalDivs = 3;

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDiv((prevActiveDiv) => (prevActiveDiv + 1) % totalDivs);
    }, 8000);

    return () => {
      clearInterval(interval);
    };
  }, []);
  // console.log(decodeNames("vijay\uD83D\uDE02\uD83E\uDEE1"))

  // useEffect(() => {
  //   // Simulate animation start
  //   const animationTimeout = setTimeout(() => {
  //     // Animation is finished, toggle the visibility
  //     setIsDiv1Visible((prevIsDiv1Visible) => !prevIsDiv1Visible);
  //   }, 10000); // Adjust the time as needed

  //   // Clean up the timeout if the component unmounts or animation is interrupted
  //   return () => clearTimeout(animationTimeout);
  // }, []); // The empty dependency array means this effect runs once, like componentDidMount

  return (
    <>
      {" "}
      {scores.result === null && scores.information === null ? (
        <BeforeStart />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <TossInfo />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <SuperOverTossBottom data={scores.match_type} />
      ) : (
        ""
      )}
      {scores.result === "Innings break" ? <BattingSummary /> : ""}
      {scores.status === "Completed" ? <BattingSummary /> : ""}
      <div className="position-relative" style={{ height: "100vh" }}>
        {ballText}

        {/* {scores.striker.RUNS === 0 &&
        scores.striker.BALLS === 0 &&
        scores.overs === 0 ? (
          showTicker ? (
            <PlayerInfo strikerData={scores.striker} />
          ) : scores.non_striker.RUNS === 0 &&
            scores.non_striker.RUNS === 0 &&
            scores.overs === 0 ? (
            <NonStrikerPlayerInfo nonStrikerData={scores.non_striker} />
          ) : (
            ""
          )
        ) : (
          ""
        )} */}
        {scores.striker.RUNS === 0 &&
        scores.striker.BALLS === 0 &&
        scores.overs === 0 ? (
          showTicker ? (
            <PlayerInfo strikerData={scores.striker} />
          ) : (
            <div data-aos="fade-up" data-aos-delay="500"></div>
          )
        ) : (
          <div data-aos="fade-up" data-aos-delay="500"></div>
        )}
        <div>
          {scores.striker.RUNS === 0 &&
          scores.striker.BALLS === 0 &&
          scores.overs !== 0 ? (
            <PlayerInfo strikerData={scores.striker} />
          ) : (
            <div data-aos="fade-up" data-aos-delay="500"></div>
          )}
        </div>
        {/* <div>
          {scores.striker.RUNS === 0 &&
          scores.striker.BALLS === 0 &&
          scores.overs !== 0 ? (
            <PlayerInfo strikerData={scores.striker} />
          ) : (
            ""
          )}
          {scores.non_striker.RUNS === 0 &&
          scores.non_striker.BALLS === 0 &&
          scores.overs !== 0 ? (
            <NonStrikerPlayerInfo nonStrikerData={scores.non_striker} />
          ) : (
            ""
          )}
        </div> */}

        <div className="position-absolute bottom-0  mb-2">
          <div id="border"></div>
          <div className="position-relative">
            <div id="tikcer_left_pink_border"></div>
            <div id="ticker_left_green_layer" className="" style={{}}>
              {scores.bats_man[0] === undefined ? (
                ""
              ) : scores.bats_man[0].IS_STRIKER === "1" ? (
                <div id="t1_striker_arrow"></div>
              ) : (
                ""
              )}
              <div id="t1_player1_text">
                {scores.bats_man[0] === undefined
                  ? ""
                  : decodeNames(scores.bats_man[0].FULL_NAME)}
              </div>{" "}
              <div id="t1_player_runs">
                {scores.bats_man[0] === undefined
                  ? ""
                  : scores.bats_man[0].RUNS}

                {scores.bats_man[0] === undefined
                  ? ""
                  : "(" + scores.bats_man[0].BALLS + ")"}
              </div>
            </div>

            <div id="ticker_white_layer">
              <div id="t1_teama_name">
                {he.decode(decodeNames(scores.teamA_short_name))}
              </div>
              <div id="ticker_pink_layer">
                <div id="t1_runs_wickets">
                  {scores.curr_runs}-{scores.wickets}
                </div>
              </div>
              <div id="t1_vs_text">
                VS
                <span id="t1_chasing_team">
                  {he.decode(decodeNames(scores.teamB_short_name))}
                </span>
              </div>
            </div>
            <div id="tikcer_right_pink_border"></div>
            <div id="ticker_right_green_layer">
              {scores.bats_man[1] === undefined ? (
                ""
              ) : scores.bats_man[1].IS_STRIKER === "1" ? (
                <div id="t1_non_striker_arrow"></div>
              ) : (
                ""
              )}

              <div id="t1_player2_text">
                <span id="t1_player2_name">
                  {scores.bats_man[1] === undefined
                    ? ""
                    : decodeNames(scores.bats_man[1].FULL_NAME)}
                </span>
                <span id="t2_player_runs">
                  {scores.bats_man[1] === undefined
                    ? ""
                    : scores.bats_man[1].RUNS}

                  {scores.bats_man[1] === undefined
                    ? ""
                    : "(" + scores.bats_man[1].BALLS + ")"}
                </span>
              </div>
            </div>
            <div id="ticker_blue_layer">
              {scores.current_inning === 2 ? (
                <div id="partnership_bg">
                  <>
                    {" "}
                    {scores.overs >= 1 ? (
                      <>
                        <div id="partnership_runs">
                          CRR: <span id="partner_runs">{scores.crr}</span>
                        </div>
                        <div id="partnership_runs">
                          RRR: <span id="partner_runs">{scores.rrr}</span>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{ display: activeDiv === 0 ? "block" : "none" }}
                    >
                      <div>{decodeNames(he.decode(scores.information))}</div>
                    </div>
                    <div
                      style={{ display: activeDiv === 1 ? "block" : "none" }}
                    >
                      {scores.last_ball === undefined ? (
                        ""
                      ) : scores.last_ball.RUN === 4 ||
                        scores.last_ball.RUN === 6 ||
                        scores.last_ball.EXTRAS >= 1 ? (
                        <div>
                          MATCH BOUNDARIES - FOURS:{" "}
                          <span id="partner_runs">{scores.total_four}</span> |
                          SIXES:{" "}
                          <span id="partner_runs">{scores.total_six}</span> |
                          EXTRAS:{" "}
                          <span id="partner_runs">
                            {scores.extras === undefined
                              ? ""
                              : scores.extras.TOTAL_EXTRA}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div
                      style={{ display: activeDiv === 2 ? "block" : "none" }}
                    >
                      {scores.partnership.TOTAL_RUNS >= 20 &&
                      (scores.partnership.TOTAL_RUNS <= 30 ||
                        scores.partnership.TOTAL_RUNS >= 50) &&
                      scores.partnership.TOTAL_RUNS <= 60 ? (
                        <div className="d-flex" style={{ gap: "2.5vh" }}>
                          <div id="partnership_runs">
                            C. Partnership:{" "}
                            <span id="partner_runs">
                              {scores.partnership.TOTAL_RUNS}(
                              {scores.partnership.TOTAL_BALLS})
                            </span>
                          </div>
                          <div id="partner1_data">
                            <span id="partner1_name">
                              {" "}
                              {scores.striker === undefined
                                ? ""
                                : decodeNames(scores.striker.NAME)}
                            </span>

                            <span id="partner_runs">
                              {" "}
                              {scores.striker === 0 ? "" : scores.striker.RUNS}(
                              {scores.striker === 0 ? "" : scores.striker.BALLS}
                              )
                            </span>
                          </div>
                          <div id="partner1_data">|</div>
                          <div id="partner2_data">
                            <span id="partner2_name">
                              {" "}
                              {scores.non_striker === 0
                                ? ""
                                : decodeNames(scores.non_striker.NAME)}{" "}
                            </span>

                            <span id="partner_runs">
                              {scores.non_striker === 0
                                ? ""
                                : scores.non_striker.RUNS}
                              (
                              {scores.non_striker === 0
                                ? ""
                                : scores.non_striker.BALLS}
                              )
                            </span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </>
                </div>
              ) : (
                ""
              )}
              {scores.current_inning === 1
                ? showDiv1 && (
                    <div id="partnership_bg">
                      {scores.overs >= 1.0 ? (
                        <div id="partnership_runs">
                          CRR: <span id="partner_runs">{scores.crr}</span>
                        </div>
                      ) : (
                        ""
                      )}

                      {scores.last_ball === undefined ? (
                        ""
                      ) : scores.last_ball.RUN === 4 ||
                        scores.last_ball.RUN === 6 ||
                        scores.last_ball.EXTRAS >= 1 ? (
                        <div>
                          MATCH BOUNDARIES - FOURS:{" "}
                          <span id="partner_runs">{scores.total_four}</span> |
                          SIXES:{" "}
                          <span id="partner_runs">{scores.total_six}</span> |
                          EXTRAS:{" "}
                          <span id="partner_runs">
                            {scores.extras === undefined
                              ? ""
                              : scores.extras.TOTAL_EXTRA}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                : ""}
              {scores.current_inning === 1
                ? showDiv2 && (
                    <div id="partnership_bg">
                      {scores.overs >= 1 ? (
                        <div id="partnership_runs">
                          CRR: <span id="partner_runs">{scores.crr}</span>
                        </div>
                      ) : (
                        ""
                      )}
                      {scores.partnership.TOTAL_RUNS >= 20 &&
                      (scores.partnership.TOTAL_RUNS <= 30 ||
                        scores.partnership.TOTAL_RUNS >= 50) &&
                      scores.partnership.TOTAL_RUNS <= 60 ? (
                        <>
                          <div id="partnership_runs">
                            C. Partnership:{" "}
                            <span id="partner_runs">
                              {scores.partnership.TOTAL_RUNS}(
                              {scores.partnership.TOTAL_BALLS})
                            </span>
                          </div>
                          <div id="partner1_data">
                            <span id="partner1_name">
                              {" "}
                              {scores.striker.NAME}{" "}
                            </span>

                            <span id="partner_runs">
                              {" "}
                              {scores.striker.RUNS}({scores.striker.BALLS})
                            </span>
                          </div>
                          <div id="partner1_data">|</div>
                          <div id="partner2_data">
                            <span id="partner2_name">
                              {" "}
                              {decodeNames(scores.non_striker.NAME)}{" "}
                            </span>

                            <span id="partner_runs">
                              {scores.non_striker.RUNS}(
                              {scores.non_striker.BALLS})
                            </span>
                          </div>
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  )
                : ""}
              <div id="base">
                <div id="t1_overs_text">
                  OVERS <span id="t1_current_over">{scores.overs}</span>
                </div>
              </div>{" "}
              <div
                id="t1_bowler_name"
                className="text"
                // style={{
                //   opacity:
                //     scores.bowler_overs === "1.0" ||
                //     scores.bowler_overs === "1.0" + 1
                //       ? "0.3"
                //       : "1",
                // }}
              >
                <span id="t1_bowler">{decodeNames(scores.bowler_name)}</span>
                <span id="t1_bowler_runs_balls">
                  {" "}
                  {scores.bowler === 0
                    ? ""
                    : scores.bowler_runs +
                      "-" +
                      scores.bowler_wickets +
                      "(" +
                      scores.bowler_overs +
                      ")"}
                </span>
              </div>
              <div
                id="t1_current_balls"
                style={{
                  flex: "",
                  overflowX: "scroll",
                  width: "36.5vh",
                  // whiteSpace: "nowrap",
                  display: "flex",
                  position: "absolute",
                  direction: "rtl",
                  // justifyContent:"right"
                  // left:"75vw"
                }}
              >
                <div
                  className="d-flex justify-content-left"
                  style={{ direction: "ltr" }}
                >
                  {scores.curr_overs
                    .slice(0)
                    .reverse()
                    .map((overs) => {
                      if (
                        overs.RUNS === 4 &&
                        overs.WICKET === 0 &&
                        overs.EXTRAS === 0
                      ) {
                        return (
                          <>
                            <div
                              id="t1_single_balls"
                              style={{ background: "#1E8E3E", color: "white" }}
                            >
                              <span
                                style={{
                                  height: "4.5vh",
                                  width: "4.5vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {overs.RUNS}
                              </span>
                            </div>
                          </>
                        );
                      } else if (
                        overs.RUNS === 6 &&
                        overs.WICKET === 0 &&
                        overs.EXTRAS === 0
                      ) {
                        return (
                          <>
                            {" "}
                            <div
                              id="t1_single_balls"
                              style={{ background: "#1E8E3E", color: "white" }}
                            >
                              <span
                                style={{
                                  height: "4.5vh",
                                  width: "4.5vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {overs.RUNS}
                              </span>
                            </div>
                          </>
                        );
                      } else if (overs.WICKET === 1) {
                        return (
                          <>
                            {" "}
                            <div
                              id="t1_single_balls"
                              className="d-flex flex-column align-items-center justify-content-center"
                              style={{ background: "#D93025", color: "white" }}
                            >
                              {overs.WICKET === 1 &&
                              overs.RUNS === 0 &&
                              overs.EXTRAS === 0 ? (
                                <>
                                  <span
                                    style={{
                                      height: "4.5vh",
                                      width: "4.5vh",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {"W"}
                                  </span>
                                </>
                              ) : (
                                <>
                                  <span
                                    style={{
                                      fontSize: "1.5vh",
                                      lineHeight: "1.7vh",
                                      fontWeight: "400",
                                      height: "4.5vh",
                                      width: "4.5vh",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {overs.TOTAL_RUNS}
                                  </span>
                                  <span
                                    style={{
                                      fontSize: "1.5vh",
                                      lineHeight: "1.7vh",
                                      fontWeight: "400",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {"W"}
                                  </span>
                                </>
                              )}
                            </div>
                          </>
                        );
                      } else
                        return (
                          <>
                            {" "}
                            <div
                              id="t1_single_balls"
                              className="d-flex flex-column "
                            >
                              {overs.EXTRAS !== 0 ? (
                                <div className="">
                                  <span
                                    className=""
                                    style={{
                                      fontSize: "1.3vh",
                                      lineHeight: "1.6vh",
                                      // height: "4.5vh",
                                      width: "4.5vh",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {overs.TOTAL_RUNS}
                                  </span>
                                  <span
                                    className="t1_extras_balls"
                                    style={{
                                      fontSize: "1.3vh",
                                      lineHeight: "1.6vh",
                                      // height: "4.5vh",
                                      width: "4.5vh",
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "center",
                                    }}
                                  >
                                    {overs.EXTRAS_TYPE}
                                  </span>
                                </div>
                              ) : (
                                <span
                                  style={{
                                    height: "4.5vh",
                                    width: "4.5vh",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  {overs.RUNS}
                                </span>
                              )}
                            </div>
                          </>
                        );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Ticker;
