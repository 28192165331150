import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import Four from "./Components/T1/Four";
// import RatioTicker from "./Components/T1/RatioTicker";
import Teams from "./Components/T1/Teams";
import BattingSummary from "./Components/T1/BattingSummary";
import BowlingSummary from "./Components/T1/BowlingSummary";
import TossInfo from "./Components/T1/TossInfo";
import BeforeStart from "./Components/T1/BeforeStart";
import PlayerInfo from "./Components/T1/PlayerInfo";
import MatchSummary from "./Components/T1/MatchSummary";
import scorecard_bg from "./Assets/ticker_bg3.png";
import Ticker2 from "./Components/T2/Ticker";
import T2BeforeStart from "./Components/T2/BeforStart";
import T2Toss from "./Components/T2/T2Toss";
import T2PlayerInfo from "./Components/T2/T2PlayerInfo";
import T2Teams from "./Components/T2/T2Teams";
import T2MatchSummary from "./Components/T2/T2MatchSummary";
import T2BattingSummary from "./Components/T2/T2BattingSummary";
import T2BowlingSummary from "./Components/T2/T2BowlingSummary";
import T2Four from "./Components/T2/Four";
import Aos from "aos";
import "aos/dist/aos.css";
import T2Six from "./Components/T2/Six";
import T2Out from "./Components/T2/Out";
// import FourIos from "./Components/T2/FourIos";

// import FourIos from "./Components/T2/FourIos";
// import Lottiefour from "./Components/T2/LottieFour";

import cd_logo from "./Assets/cd_logo.png";
import Lottiefour from "./Components/T2/LottieFour";
import Ticker from "./Components/T1/Ticker";

import T1Four from "./Components/T1/T1Four";
import T1Out from "./Components/T1/T1Out";
import T1Six from "./Components/T1/T1Six";
import T3BattingSummary from "./Components/T3/T3BattingSummary";
import T3Ticker from "./Components/T3/Ticker";
import T3Teams from "./Components/T3/T3Teams";
import T3MatchSummary from "./Components/T3/T3MatchSummary";
import T3Toss from "./Components/T3/T3Toss";
import T3BeforeStart from "./Components/T3/T3BeforeStart";
import T3BowlingSummary from "./Components/T3/T3BowlingSummary";
import T3PlayerInfo from "./Components/T3/T3PlayerInfo";
import InningsBreakBottom from "./Components/T1/InningsBreakBottom";
import T3InningsBreak from "./Components/T3/T3InningsBreak";
import T3SummaryBottom from "./Components/T3/T3SummaryBottom";
import T2Ticker from "./Components/T2/T2Ticker";
import T2InningsBreakBottom from "./Components/T2/T2InningsBreakBottom";
import T2SummaryBottom from "./Components/T2/T2SummaryBottom";
import T4Ticker from "./Components/T4/Ticker";

function App() {
  Aos.init({
    duration: 400,
    easing: "ease-out",
  });
  Aos.refresh();
  function refreshPage() {
    setTimeout(() => {
      window.location.reload(false);
    }, 500);
    console.log("page to reload");
  }
  const [activeDiv, setActiveDiv] = useState(0); // 0 for the first div
  const totalDivs = 3; // Number of divs to cycle through

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveDiv((prevActiveDiv) => (prevActiveDiv + 1) % totalDivs);
    }, 2000); // Switch div every 10 seconds (10000 milliseconds)

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div
      // id="ratio_bg"
      className=""
      style={{
        // backgroundImage: `url(${scorecard_bg})`,
        height: "100vh",
        // aspectRatio:"16/9",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100vw",
        objectFit: "cover",
        overflow: "hidden",
      }}
    >
      {/* <div id="center_bg">
        <div id="center_text_bg1">TEXT Center</div>
      </div> */}

      <div id="cd_logo" className="position-absolute end-0">
        <img
          className=""
          src={cd_logo}
          alt="cd_logo"
          style={{
            height: "11.6vh",
            width: "14.9vh",
            marginTop: "5vh",
            marginRight: "3vh",
          }}
        />
      </div>

      <BrowserRouter>
        <Routes>
          <Route path="/t1" element={<Ticker />} />
          <Route path="/t1/four" element={<T1Four />} />
          <Route path="/t1/six" element={<T1Six />} />
          <Route path="/t1/out" element={<T1Out />} />
          <Route path="t1/teams" element={<Teams />} />
          <Route path="t1/batting-summary" element={<BattingSummary />} />
          <Route path="t1/bowling-summary" element={<BowlingSummary />} />
          <Route path="t1/toss" element={<TossInfo />} />
          {/* <Route path="t1/ticker" element={<RatioTicker />} /> */}
          <Route path="t1/player-info" element={<PlayerInfo />} />
          <Route path="t1/match-summary" element={<MatchSummary />} />
          <Route path="t1/start" element={<BeforeStart />} />
          <Route path="t1/break" element={<InningsBreakBottom />} />
          {/* Ticker 2 Routes */}
          <Route path="/t2" element={<T2Ticker />} />
          <Route path="/t2/before-start" element={<T2BeforeStart />} />
          <Route path="/t2/toss" element={<T2Toss />} />
          <Route path="/t2/player-info" element={<T2PlayerInfo />} />
          <Route path="/t2/teams" element={<T2Teams />} />
          <Route path="/t2/match-summary" element={<T2MatchSummary />} />
          <Route path="/t2/batting-summary" element={<T2BattingSummary />} />
          <Route path="/t2/bowling-summary" element={<T2BowlingSummary />} />
          {/* <Route path="/t2/four" element={<T2Four />} />
          <Route path="/t2/six" element={<T2Six />} />
          <Route path="/t2/out" element={<T2Out />} /> */}
          <Route path="/t2/four-ios" element={<Lottiefour />} />
          <Route path="/t2/break" element={<T2InningsBreakBottom />} />
          <Route path="/t2/summary-break" element={<T2SummaryBottom />} />
          {/* Ticker 3 Routes */}
          <Route path="/t3" element={<T3Ticker />} />
          <Route path="/t3/batting-summary" element={<T3BattingSummary />} />
          <Route path="/t3/teams" element={<T3Teams />} />
          <Route path="/t3/match-summary" element={<T3MatchSummary />} />
          <Route path="/t3/toss" element={<T3Toss />} />
          <Route path="/t3/before-start" element={<T3BeforeStart />} />
          <Route path="/t3/bowling-summary" element={<T3BowlingSummary />} />
          <Route path="/t3/player-info" element={<T3PlayerInfo />} />
          <Route path="/t3/break" element={<T3InningsBreak />} />
          <Route path="/t3/summary-bottom" element={<T3SummaryBottom />} />

          {/* Ticker 4 Routes */}
          <Route path="/t4" element={<T4Ticker />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
