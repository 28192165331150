import Lottie from "react-lottie";
import React from "react";
import four_lottie from "../../Assets/t3/t3_four.json";

function T3Four() {

  const defaultOptions = {
    animationData: four_lottie,
    autplay: true,
    loop: false,
  };
;
  return (
    <div>
      <Lottie options={defaultOptions} height="100vh"
          width="100vh"
          style={{ backgroundSize: "cover", height: "100vh", width: "100vw" }} />
    </div>
  );
}

export default T3Four;
