import React, { useEffect, useState } from "react";
import "../../Styles/T3/T3TossInfo.css";
import india_logo from "../../Assets/t3_india_img.png";
import pakistan_logo from "../../Assets/t3_pakistan_img.png";
import { useSearchParams } from "react-router-dom";
import socketIOClient from "socket.io-client";
import T3Teams from "./T3Teams";
import team_default_logo from "../../Assets/t3_team_default_img.png";
import he from "he";

function T3Toss(props) {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_logo: "",
    teama_short_name: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    result: "",
    toss_won_team: "",
    toss_decision: "",
    match_type: "",
    total_overs: "",
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teama_logo: mainData.TEAM_A.IMAGE_URL,
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teamb_logo: mainData.TEAM_B.IMAGE_URL,
        result: mainData.RESULT,
        toss_decision: data.TOSS.TOSS_DECISION,
        toss_won_team:data.TOSS,
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        match_type: mainData.TYPE,
        total_overs: mainData.TOTAL_OVER,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const playerNameCount = 15; // Set the maximum letter count
  const strikerElements = document.querySelectorAll(".t3_teamb_player_name");
  strikerElements.forEach(striker => {
    if (striker.textContent.length > playerNameCount) {
      const truncatedText = striker.textContent.substring(0, playerNameCount);
      striker.textContent = truncatedText + "...";
    }
  });
  
  

  return (
    <div>
      {showTicker ? (
        <div id="t3_toss_bg">
          <div id="t3_toss_text_bg">TOSS</div>
          <div id="t3_toss_match_start" align="center">
            Match Will Start Soon - {teamName.total_overs} {teamName.total_overs === 1 ? "OVER" : "OVERS"} Match
          </div>
          <div id="t3_toss_teama">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teama_logo}
              alt="teama_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
            <div id="t3_toss_teama_name">
              {he.decode(decodeNames(teamName.teama_short_name))}
            </div>
            {/* <div id="t3_toss_teama_fullname" className="t3_teamb_player_name"> 
              {he.decode(decodeNames(teamName.teama_name))}
            </div> */}
          </div>
          <div id="t3_toss_vs_text">VS</div>
          <div id="t3_toss_teamb">
            <img
              src={process.env.REACT_APP_API_URL + teamName.teamb_logo}
              alt="teama_logo"
              onError={(e) => (e.target.src = team_default_logo)}
            />
            <div id="t3_toss_teamb_name">
              {he.decode(decodeNames(teamName.teamb_short_name))}
            </div>
            {/* <div id="t3_toss_teamb_fullname">
              {he.decode(decodeNames(teamName.teamb_name))}
            </div> */}
          </div>
          <div id="t3_toss_result_bg">
            <span style={{ fontWeight: "700" }}>
              {" "}
              {`${decodeNames((teamName.toss_won_team.SHORT_TEAM_NAME))}`}
            </span>
            <span className="ps-2">
              Won the toss & opt to {teamName.toss_decision}
            </span>
          </div>
        </div>
    ) : (
        <T3Teams />
      )}   
    </div>
  );
}

export default T3Toss;
