import React, { useEffect, useState } from "react";
// import scorecard_bg from "../../Assets/scorecard_bg.png";
import batting_team_logo from "../../Assets/batting_team_logo.png";
// import batting_summary_effect from "../../Assets/batting_summary_effect.png";
import "../../Styles/T1/BattingSummary.css";
import qs from "qs";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import team_deault_img from "../../Assets/team_logo.png";
import BowlingSummary from "./BowlingSummary";
import he from "he";

function BattingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");
  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    team_name: "",
    team_extras: "",
    team_overs: "",
    runs: "",
    wickets: "",
    team_nick_name: "",
    curr_innings: "",
    non_batting_players: [],
  });

  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  const decodeNames = (x) => {
    // const [teamImage, setTeamImage] = useState({batting_team_logo})
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");

      let responseData =
        res.data.XSCData.BATTING_TEAM_DETAILS === undefined
          ? ""
          : res.data.XSCData;
      setSummary({
        player_names: responseData.BATTING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        non_batting_players:
          responseData.BATTING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: he.decode(responseData.BATTING_TEAM_DETAILS.TEAM_NAME),
        team_logo: responseData.BATTING_TEAM_DETAILS.TEAM_URL,
        team_extras: responseData.BATTING_TEAM_DETAILS.EXTRAS,
        team_overs: responseData.BATTING_TEAM_DETAILS.OVERS,
        runs: responseData.BATTING_TEAM_DETAILS.RUNS,
        wickets: responseData.BATTING_TEAM_DETAILS.WICKETS,
        team_nick_name: responseData.BATTING_TEAM_DETAILS.TEAM_NICK_NAME,
        curr_innings: responseData.CURRENT_INNINGS,
      });
      // console.log(summary.team_logo, "logo");
    });
  }, []);
  let battingTeamName = he.decode(summary.team_name);
  const [teamLogo, setTeamLogo] = useState(summary.team_logo);
  const handleImage = (e) => {
    e.target.src = team_deault_img;
  };
  const [showTicker, setShowTicker] = useState(true);
  let [nonPlayer, setNonPlayer] = useState([]);
  // useEffect(() => {
  //   summary.player_names.filter((player) => {
  //     setNonPlayer(player.RUNS === "");
  //     console.log(nonPlayer, "nn");
  //   });
  // }, []);

  // console.log(filteredPlayerDetails, "jk");
  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  const maxCount = 11;

  // Conditionally render the second array based on the length of the first array
  const renderSecondArray = summary.player_names.length < maxCount;
  // console.log(he.decode("csk&#039;&#039;", "jks"));
  // console.log(he.decode("csk&#039;&#039; team "));
  // console.log(decodeNames(decodeNames("gsgsgs\\u201d\\u201d/):\\u20b9:!.!")));

  const maxLetterCount = 22; // Set the maximum letter count

  const batting_team_name = document.getElementById(
    "batting_team_text"
  );
  if (batting_team_name) {
    if (batting_team_name.textContent.length > maxLetterCount) {
      const truncatedText = batting_team_name.textContent.substring(
        0,
        maxLetterCount
      );
      batting_team_name.textContent = truncatedText + "...";
    }
  }
  return (
    <>
      {showTicker ? (
        <div
          data-aos="zoom-in"
          data-aos-delay="500"
          style={{
            // backgroundImage: `url(${scorecard_bg})`,
            height: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // width: "100vw",
          }}
          className="d-flex position-relative"
        >
          <div
            id="batting_summary_white_bg"
            // style={{ background: "white", width: "1128px", zIndex: "99" }}
            className="m-auto position-relative"
          >
            {" "}
            <div
              id="batting_summary_teama_name_bg"
              className="position-absolute d-flex justify-content-between align-items-center ps-5 translate-middle-y"
            >
              <span
                id="batting_summary_logo_outer_gradient"
                className="position-absolute  z-99 top-0"
              >
                <div id="batting_summary_logo_inner_gradient">
                  <div
                    id="summary_team_logo"
                    className="d-flex justify-content-center"
                  >
                    <img
                      src={process.env.REACT_APP_API_URL + summary.team_logo}
                      style={{
                        height: "7vh",
                        width: "7vh",
                        borderRadius: "2.6vw",
                      }}
                      alt="logo"
                      width="100%"
                      onError={handleImage}
                    ></img>
                  </div>
                </div>
              </span>{" "}
              <div id="batting_team_text" className="">
                {he.decode(decodeNames(`${summary.team_nick_name}`))} -{" "}
                {decodeNames(he.decode(`${summary.team_name}`))}
              </div>
              <div
                id="batting_summary_text"
                className="position-absolute bottom-0 end-0 "
              >
                {summary.curr_innings === "1"
                  ? "INNINGS 1 - BATTING SUMMARY"
                  : ""}
                {summary.curr_innings === "2"
                  ? "INNINGS 2 - BATTING SUMMARY"
                  : ""}
              </div>
            </div>
            <div className="row   " id="teams_content">
              {summary.player_names === undefined
                ? ""
                : (() => {
                    const battingPlayer = summary.player_names
                      .filter((player) => player.RUNS !== "")
                      // .sort((a, b) => b.RUNS - a.RUNS)
                      .slice(0, 11); // First array gets up to 11 items

                    const nonBattingPlayers = summary.player_names
                      .filter((player) => player.RUNS === "")
                      .slice(0, Math.max(0, 11 - battingPlayer.length)); // Second array gets the remaining items

                    const combinedList = [
                      ...battingPlayer,
                      ...nonBattingPlayers,
                    ];

                    return combinedList.map((players, index) => {
                      return (
                        <div className="d-flex" id="batting_teams" key={index}>
                          <div
                            id={
                              players.RUNS !== ""
                                ? "batting_team_players"
                                : "non_batting_players"
                            }
                          >
                            <div className="row">
                              <div
                                className="col-5"
                                id="batting_summary_batsman_text"
                              >
                                <div
                                  id={
                                    players.OUT_TEXT === "NOT OUT"
                                      ? "not_out_player_indicator"
                                      : ""
                                  }
                                ></div>
                                <span id="player_text_width">
                                  {decodeNames(players.NAME)}
                                </span>
                              </div>
                              <div
                                className="col-3"
                                id="batting_summary_catcher_text"
                              >
                                {players.OUT_TEXT === "NOT OUT"
                                  ? players.OUT_TEXT
                                  : players.HOW_OUT === "CAUGHT"
                                  ? "c " + decodeNames(players.FIELDER)
                                  : players.OUT_TEXT === "OUT"
                                  ? players.HOW_OUT === "BOWLED"
                                    ? ""
                                    : players.HOW_OUT
                                  : ""}
                              </div>
                              <div
                                className="col-4"
                                id="batting_summary_bowler_name"
                              >
                                {players.HOW_OUT === "BOWLED" ||
                                players.HOW_OUT === "CAUGHT"
                                  ? "b " + decodeNames(players.BOWLER)
                                  : ""}
                              </div>
                            </div>
                          </div>
                          <div
                            id={
                              players.RUNS !== ""
                                ? "batting_summary_player_runs"
                                : ""
                            }
                            className="d-flex justify-content-center align-items-center"
                          >
                            {" "}
                            <span
                              style={{
                                textAlign: "left",
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              {players.RUNS}
                            </span>
                            <span
                              id="t1_player_balls"
                              className="px-2"
                              style={{
                                display: "flex",
                                justifyContent: "left",
                              }}
                            >
                              {players.BALLS}
                            </span>
                          </div>
                        </div>
                      );
                    });
                  })()}

              <div className="row " id="extras_row">
                <div
                  id="extras_bg"
                  className="col-9 d-flex justify-content-between align-items-center"
                >
                  <div>
                    Extras{" "}
                    {summary.team_extras === null ? "0" : summary.team_extras}
                  </div>
                  <div className="pe-5 " id="">
                    Overs {summary.team_overs}
                  </div>
                </div>
                <div
                  id="batting_summary_result"
                  className="col-3 d-flex justify-content-center align-items-center  position-absolute  end-0  translate-middle"
                >
                  {summary.runs}-{summary.wickets}
                </div>
              </div>
            </div>
          </div>

          <div
            id="batting_outer_gradient_layout"
            className="position-absolute "
          ></div>
          <div
            id="batting_inner_gradient_layout"
            className="position-absolute"
          ></div>
        </div>
      ) : (
        <BowlingSummary />
      )}
    </>
  );
}

export default BattingSummary;
