import React, { useState, useEffect } from "react";
import scorecard_bg from "../../Assets/scorecard_bg.png";
// import teams_bg from "../../Assets/team_bg.png";
import teams_gradient_effect from "../../Assets/team_gradient_effect.png";
import "../../Styles/T1/Teams.css";
import { useSearchParams } from "react-router-dom";
import socketIOClient, { managers } from "socket.io-client";
import BeforeStart from "./BeforeStart";
import TossBottom from "./TossBottom";
import he from "he";
function Teams() {
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;
  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");

  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };

  const [teamName, setTeamName] = useState({
    teama_name: "",
    teama_short_name: "",
    teama_logo: "",
    teamb_name: "",
    teamb_logo: "",
    teamb_short_name: "",
    team_a_players: [],
    team_b_players: [],
    teama_squad_type: [],
    teamb_squad_type: [],
  });

  useEffect(() => {
    const socket = socketIOClient(ENDPOINT);
    socket.on("connected", (data) => {
      // console.log("connected");
    });
    socket.on("live_score_new", (data) => {
      console.log(data);

      let mainData = data;
      setTeamName({
        teama_name: he.decode(mainData.TEAM_A.TEAM_NAME),
        teamb_name: he.decode(mainData.TEAM_B.TEAM_NAME),
        teama_short_name: he.decode(mainData.TEAM_A.TEAM_NICK_NAME),
        teamb_short_name: he.decode(mainData.TEAM_B.TEAM_NICK_NAME),
        team_a_players: mainData.TEAM_A.TEAM_SQUAD,
        team_b_players: mainData.TEAM_B.TEAM_SQUAD,
      });
    });
    socket.emit("join", "match_id", match_id);
    socket.emit("get_score", match_id);

    return () => socket.disconnect();
  }, []);

  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  return (
    <>
      {showTicker ? (
        <div
          id="whole_design"
          style={{
            // backgroundImage: `url(${scorecard_bg})`,
            height: "100vh",
          }}
          className="d-flex position-relative"
        >
          <div id="teams_outer_gradient_layout"></div>
          <div
            id="teams_inner_gradient_layout"
            className="position-absolute"
          ></div>
          <div id="teams_gradient_bg" className=" position-absolute"></div>
          <div id="teams_white_bg" className="m-auto position-relative">
            <div
              // data-aos="fade-down"
              id="teams_playing_text"
              className=" top-25 start-5 translate-middle "
            >
              TEAMS PLAYING
            </div>
            <div className="row" id="teams_row_width">
              <div className="col-6 mt-2  ">
                <div
                  className="d-flex align-items-baseline"
                  // data-aos="fade-left"
                >
                  <span id="teams_teama_short_name">
                    {he.decode(decodeNames(teamName.teama_short_name))}
                  </span>{" "}
                  <span
                    id="teams_teama_full_name"
                    className="ps-2"
                    style={{ textTransform: "uppercase" }}
                  >
                    ({he.decode(decodeNames(teamName.teama_name))})
                  </span>
                </div>
                <div className="">
                  {/* {console.log(teamName.team_a_players.sort(), "asplayers")} */}
                  {teamName.team_a_players.slice(0, 11).map((playersA) => {
                    return (
                      <div id="teama_player_name">
                        {decodeNames(playersA.FULL_NAME)}
                        <span
                          id="teama_player_detail"
                          className=""
                          // data-aos="fade-down"
                        >
                          {playersA.SQUAD_TYPE}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-6 mt-1">
                <div className="d-flex align-items-baseline">
                  <div id="teams_teamb_short_name">
                    {he.decode(decodeNames(teamName.teamb_short_name))}
                  </div>{" "}
                  <div id="teams_teamb_full_name" className="ps-2">
                    ({he.decode(decodeNames(teamName.teamb_name))})
                  </div>
                </div>
                <div className="">
                  {teamName.team_b_players.slice(0, 11).map((playersB) => {
                    return (
                      <div id="teama_player_name">
                        {decodeNames(playersB.FULL_NAME)}
                        <span
                          id="teama_player_detail"
                          className=""
                          // data-aos="fade-down"
                        >
                          {playersB.SQUAD_TYPE}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
       <TossBottom />
       )}
    </>
  );
}

export default Teams;
