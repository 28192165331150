import React, { useState, useEffect } from "react";
import t2_teams_title_bg from "../../Assets/T2/batting_summary_title_bg.png";
import "../../Styles/T2/T2BowlingSummary.css";
import t2_teams_bg from "../../Assets/T2/teams_white_bg.png";
import t2_teama_logo from "../../Assets/T2/t2_teama_logo.png";
import { useSearchParams } from "react-router-dom";
import axios from "axios";
import qs from "qs";
import T2InningsBreakBottom from "./T2InningsBreakBottom";
import team_default_logo from "../../Assets/T2/t2_team_default_logo.png";
import T2MatchSummary from "./T2MatchSummary";

function T2BowlingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");

  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    fall_of_wicket: [],
    score: [],
    extras: "",
    runs: "",
    wickets: "",
    team_name: "",
    overs: "",
    team_nick_name: "",
    curr_innings: "",
    status:"",

  });
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        player_names: responseData.BOWLING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: responseData.BOWLING_TEAM_DETAILS.TEAM_NAME,
        overs: responseData.BATTING_TEAM_DETAILS.OVERS,
        runs: responseData.BATTING_TEAM_DETAILS.RUNS,
        wickets: responseData.BATTING_TEAM_DETAILS.WICKETS,
        fall_of_wicket: responseData.BATTING_TEAM_DETAILS.FALL_OF_WICKET,
        team_logo: responseData.BOWLING_TEAM_DETAILS.TEAM_URL,
        curr_innings: responseData.CURRENT_INNINGS,
        team_nick_name: responseData.BOWLING_TEAM_DETAILS.TEAM_NICK_NAME,
        extras: responseData.BATTING_TEAM_DETAILS.EXTRAS,
        status:responseData.STATUS
        
      });
    });
  }, [0]);
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  return (
    <>
      {showTicker ? (
        <div className="d-flex position-relative" id="t2_bowling_font">
          <div id="t2_bowling_title_bg">
            <img src={t2_teams_title_bg} alt="team_players" width="100%" />
          </div>
          <div id="t2_bowling_teama_logo">
            <img
              src={process.env.REACT_APP_API_URL + summary.team_logo}
              alt=""
              onError={(e) => (e.target.src = team_default_logo)}
            />
          </div>
          <div id="t2_bowling_teama_short_name">{summary.team_nick_name}</div>
          <div id="t2_bowling_teama_name">({summary.team_name})</div>
          <div id="t2_bowling_bowling_summary">
            {summary.curr_innings === "1"
              ? "INNINGS 1"
              : summary.curr_innings === "2"
              ? "INNINGS 2"
              : ""}{" "}
            - Bowling summary
          </div>
          <div id="t2_bowling_teama_name"></div>
          <div id="t2_white_bowling_bg">
            <img src={t2_teams_bg} alt="t2_bowling_bg" width="100%" />
            <div className="row " id="t2_bowling_row">
              {" "}
              <div className="col-12">
                <div id="t2_bowling_player_type_bg">
                  <div id="t2_bowling_topics">
                    <div>overs</div>
                    <div>dots</div>
                    <div>runs</div>
                    <div>wickets</div>
                    <div>economy</div>
                  </div>
                </div>{" "}
                {summary.player_names === undefined
                  ? ""
                  : summary.player_names
                      .slice(0, 9)
                      .filter((player) => player.OVERS !== null)
                      .map((bowler) => {
                        return (
                          <div id="t2_bowling_player_bg">
                            <div id="t2_player_runs_width">
                              <div id="t2_bowling_player_name_bg">
                                {decodeNames(bowler.NAME)}
                              </div>
                              <div id="t2_bowling_player_overs">
                                {bowler.OVERS}
                              </div>
                              <div id="t2_bowling_player_dots">
                                {bowler.DOT_BALLS}
                              </div>
                              <div id="t2_bowling_player_wickets">
                                {bowler.RUNS}
                              </div>
                              <div id="t2_bowling_player_runs">
                                {bowler.WICKETS}
                              </div>
                              <div id="t2_bowling_player_economy">
                                {bowler.ECONOMY}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                <div
                  className="position-absolute"
                  id="t2_fall_wicket_scores_bg"
                >
                  {" "}
                  <div id="t2_bowling_player_wicket_fall_bg">
                    <div>
                      <div id="t2_bowling_player_name_bg">fall of wickets</div>
                      <div id="t2_fall_wicket">
                        {/* {summary.fall_of_wicket === undefined ? (
                    ""
                  ) : summary.fall_of_wicket === null ? (
                    <div className="col-1">-</div>
                  ) : (
                    summary.fall_of_wicket.map((wickets) => {
                      return (
                        <div className="col-1" align="center">
                          {wickets.WICKET}
                        </div>
                      );
                    })
                  )} */}
                        {summary.fall_of_wicket === undefined ? (
                          ""
                        ) : summary.fall_of_wicket === null ? (
                          <div id="t2_fall_wicket_num">-</div>
                        ) : (
                          summary.fall_of_wicket.map((wickets) => {
                            return (
                              <div id="t2_fall_wicket_num">
                                {wickets.WICKET}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                  <div id="t2_bowling_player_scores_bg">
                    <div>
                      <div id="t2_bowling_player_name_bg">scores</div>
                      <div id="t2_scores">
                        {summary.fall_of_wicket === undefined ? (
                          ""
                        ) : summary.fall_of_wicket === null ? (
                          <div id="t2_fall_wicket_num">-</div>
                        ) : (
                          summary.fall_of_wicket.map((runs) => {
                            return (
                              <div id="t2_fall_wicket_num">
                                {runs.TEAM_RUNS}
                              </div>
                            );
                          })
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id="t2_bowling_bottom_line">
            <div id="t2_bowling_extras_overs_bg">
              <div id="t2_bowling_extras">
                extras {summary.extras === null ? "0" : summary.extras}
              </div>
              <div id="t2_bowling_overs">overs {summary.overs}</div>
            </div>

            <div id="t2_bowling_result">
              {summary.runs}-{summary.wickets}
            </div>
          </div>
        </div>
      ) : summary.status === "INNINGS_BREAK" ? (
        <T2InningsBreakBottom />
      ) : summary.status === "COMPLETED" ? (
        <T2MatchSummary />
      ) : (
        ""
      )}{" "}
    </>
  );
}
export default T2BowlingSummary;
