import React, { useContext } from "react";
import "../../Styles/T2/T2Ticker.css";
import t2_team_default_img from "../../Assets/T2/t2_team_default_logo.png";
// import t2_striker_img from "../../Assets/t2_stiker_arrow.png";
import { SocketContext } from "../../socket";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
// import T2Four from "./Four";
import T2Six from "./Six";
import T2Out from "./Out";
import { useState } from "react";
import he from "he";
import BeforeStart from "./BeforStart";
import T2Toss from "./T2Toss";
import T2SummaryBottom from "./T2SummaryBottom";
import T2BattingSummary from "./T2BattingSummary";
import T2BowlingSummary from "./T2BowlingSummary";
import T2PlayerInfo from "./T2PlayerInfo";
import T2Four from "./T2Four";

function T2Ticker() {
  const socket = useContext(SocketContext);

  let [searchParams] = useSearchParams();
  const match_id = searchParams.get("match_id");
  const [scores, setScores] = useState({
    teamA_name: "",
    teamB_name: "",
    striker_name: "",
    striker_runs: "",
    striker_balls: "",
    curr_runs: "",
    wickets: "",
    overs: "",
    non_striker_id: "",
    bowler_name: "",
    bowler_wickets: "",
    bowler_runs: "",
    bowler_overs: "",
    last_ball: "",
    toss_detail: "",
    result: "",
    current_inning: "",
    information: "",
    toss_decision: "",
    striker: "",
    non_striker: "",
    status: "",
    player_id: "",
    teamA_short_name: "",
    teamB_short_name: "",
    curr_overs: [],
    extras_type: "",
    bats_man: [],
    partnership: "",
    crr: "",
    rrr: "",
    extras: "",
    total_four: "",
    total_six: "",
    bowler: "",
    match_type: "",
    curr_match_id: "",
  });

  const [ballText, setBallText] = useState(); // Initialize the state with an empty string
  const timeoutSecs = 5;
  const updateBallText = () => {
    socket.on("four", () => {
      const newBallText = <T2Four />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
    socket.on("six", () => {
      const newBallText = <T2Four />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
    socket.on("wicket", ({ currentBall }) => {
      // console.log(currentBall, "out");

      const newBallText = <T2Out />;
      setBallText(newBallText);
      // console.log(newBallText, "jk");
      setTimeout(() => {
        setBallText("");
      }, timeoutSecs * 1000);
    });
  };
  useEffect(() => {
    updateBallText();
  }, []);

  useEffect(() => {
    socket.on("connect", () => {
      console.log("connected!");
    });
    socket.on("live_score_new", (data) => {
      console.log(data, "kilsl");
      setScores({
        curr_match_id: data.MATCH_ID,
        wickets: data.BATTING.WICKETS,
        overs: data.BATTING.OVERS,
        bowler_name: data.BOWLER.NAME,
        bowler_runs: data.BOWLER.RUNS,
        bowler_wickets: data.BOWLER.WICKETS,
        bowler_overs: data.BOWLER.OVERS,
        curr_overs: data.CURRENT_OVER,
        curr_runs: data.BATTING.RUNS,
        teamA_name: he.decode(data.TEAM_A.TEAM_A_NAME),
        teamB_name: he.decode(data.TEAM_B.TEAM_B_NAME),
        result: data.RESULT,
        last_ball: data.LAST_BALL,
        toss_detail: data.RESULT,
        information: data.INFORMATION,
        toss_decision: data.TOSS.TOSS_DECISION,
        striker: data.STRIKER,
        non_striker: data.NONSTRIKER,
        current_inning: data.CURRENT_INNING,
        status: data.STATUS,
        player_id: data.STRIKER.PLAYER_ID,
        teamA_short_name: he.decode(data.TEAM_A.TEAM_NICK_NAME),
        teamB_short_name: he.decode(data.TEAM_B.TEAM_NICK_NAME),
        bats_man: data.BATSMAN,
        partnership: data.PARTNERSHIP,
        crr: data.BATTING.CRR,
        extras: data.BATTING.EXTRAS,
        rrr: data.BATTING.RRR,
        total_four: data.BATTING.TOTAL_FOUR,
        total_six: data.BATTING.TOTAL_SIX,
        bowler: data.BOWLER,
        match_type: data.TYPE,
        last_ball: data.LAST_BALL,
      });
    });
    socket.emit("join", "match_id_", match_id);
    socket.emit("get_score", match_id);
  }, []);
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const maxLetterCount = 11; // Set the maximum letter count

  const striker = document.getElementById("t2_striker_name");
  if (striker) {
    if (striker.textContent.length > maxLetterCount) {
      const truncatedText = striker.textContent.substring(0, maxLetterCount);
      striker.textContent = truncatedText + "...";
    }
  }
  const non_striker = document.getElementById("t2_non_striker_name");
  if (non_striker) {
    if (non_striker.textContent.length > maxLetterCount) {
      const truncatedText = non_striker.textContent.substring(
        0,
        maxLetterCount
      );
      non_striker.textContent = truncatedText + "...";
    }
  }
  const bowler = document.getElementById("t2_bowl_name");
  if (bowler) {
    if (bowler.textContent.length > maxLetterCount) {
      const truncatedText = bowler.textContent.substring(0, maxLetterCount);
      bowler.textContent = truncatedText + "...";
    }
  }
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 6000);
  }, []);
  return (
    <>
      {scores.result === null && scores.information === null ? (
        <BeforeStart />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type !== "SUPER_OVER" ? (
        <T2Toss />
      ) : scores.curr_overs.length === 0 &&
        scores.striker === 0 &&
        scores.current_inning === 1 &&
        scores.match_type === "SUPER_OVER" ? (
        <T2SummaryBottom data={scores.match_type} />
      ) : (
        ""
      )}
      {scores.result === "Innings break" ? <T2BattingSummary /> : ""}
      {scores.status === "Completed" ? <T2BattingSummary /> : ""}
      {scores.striker.RUNS === 0 &&
      scores.striker.BALLS === 0 &&
      scores.overs === 0 ? (
        showTicker ? (
          <T2PlayerInfo strikerData={scores.striker} />
        ) : (
          <div data-aos="fade-up" data-aos-delay="500"></div>
        )
      ) : (
        <div data-aos="fade-up" data-aos-delay="500"></div>
      )}
      <div>
        {scores.striker.RUNS === 0 &&
        scores.striker.BALLS === 0 &&
        scores.overs !== 0 ? (
          <T2PlayerInfo strikerData={scores.striker} />
        ) : (
          <div data-aos="fade-up" data-aos-delay="500"></div>
        )}
      </div>
      {(scores.striker === 0 &&
        scores.non_striker === 0 &&
        scores.bowler === 0) ||
      scores.result === "Innings break" ||
      scores.status === "Completed" ? (
        ""
      ) : (
        <>
        <div id="t2_container_bg" style={{ height: "100vh" }}>
          {/* <div
    id="t2_rectangle"
    className="position-absolute start-50 bottom-0 translate-middle-x"
    style={{
      height: "400px",
      width: "100px",
      backgroundColor: "palegreen",
      zIndex:"0"
    }}
  >
    yuvasri
  </div> */}
        {ballText}

          <div id="t2_left_bg">
            <div id="t2_left_contents">
              <div id="t2_left_logo">
                <img
                  src={process.env.REACT_APP_API_URL + scores.teamA_logo}
                  alt="india_logo"
                  className="bg-primary"
                  onError={(e) => (e.target.src = t2_team_default_img)}
                />
              </div>
              <div id="t2_arrow">
                <div id="t2_striker_arrow"></div>
                <div id="t2_striker_name">
                  {scores.bats_man[0] === undefined
                    ? ""
                    : decodeNames(scores.bats_man[0].FULL_NAME)}
                  <span id="t2_striker_runs">
                    {scores.bats_man[0] === undefined
                      ? ""
                      : scores.bats_man[0].RUNS}
                  </span>
                  <span id="t2_striker_balls">
                    {scores.bats_man[0] === undefined
                      ? ""
                      : scores.bats_man[0].BALLS}
                  </span>
                </div>
                <div id="t2_non_striker_name">
                  {scores.bats_man[1] === undefined
                    ? ""
                    : decodeNames(scores.bats_man[1].FULL_NAME)}
                  <span id="t2_non_striker_runs">
                    {scores.bats_man[1] === undefined
                      ? ""
                      : scores.bats_man[1].RUNS}
                  </span>
                  <span id="t2_non_striker_balls">
                    {scores.bats_man[1] === undefined
                      ? ""
                      : scores.bats_man[1].BALLS}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div id="t2_left_border_bg"></div>

          <div id="t2_center_bg">
            <div id="t2_center_contents">
              <div id="t2_active_team_name">{scores.teamA_short_name}</div>
              <div id="t2_vs_text">vs</div>
              <div id="t2_chasing_team">{scores.teamB_short_name}</div>
              <div id="t2_1st_st_line"></div>
              <div id="t2_team_runs_wickets_bg">
                {scores.curr_runs}-{scores.wickets}
              </div>
              <div id="t2_run_rate_text">
                CRR <span id="t2_run_rate">{scores.crr}</span>{" "}
              </div>
              <div id="t2_2nd_st_line"></div>
              <div id="t2_ov_text">OV</div>
              <div id="t2_curr_overs">{scores.overs}</div>
            </div>
          </div>

          <div id="t2_right_bg">
            <div id="t2_bowler_name">
              <span id="t2_bowl_name">{decodeNames(scores.bowler_name)}</span>
              <span id="t2_bowler_runs_balls">
                {scores.bowler_runs}-{scores.bowler_wickets}
              </span>
            </div>
            <div id="t2_bowler_st_line"></div>
            <div id="t2_bowler_overs">{scores.bowler_overs}</div>
            <div id="t2_teamb_logo">
              <img src={t2_team_default_img} alt="t2_team_default_logo" />
            </div>
            <div
              id="t2_curr_balls"
              style={{
                flex: "",
                overflowX: "scroll",
                width: "33vh",
                // whiteSpace: "nowrap",
                display: "flex",
                position: "absolute",
                direction: "rtl",
                justifyContent: "right",
                borderRadius: "4.2vh",
                marginLeft: "8.6vw",
                // left:"75vw"
                // backgroundColor: "yellow",
              }}
            >
              <div
                className="d-flex justify-content-left"
                style={{ direction: "ltr" }}
              >
                {scores.curr_overs
                  .slice(0)
                  .reverse()
                  .map((overs) => {
                    if (
                      overs.RUNS === 4 &&
                      overs.WICKET === 0 &&
                      overs.EXTRAS === 0
                    ) {
                      return (
                        <>
                          <div id="t2_single_balls">
                            <span
                              style={{
                                fontSize: "1.5vh",
                                lineHeight: "1.7vh",
                                fontWeight: "700",
                                height: "4.2vh",
                                width: "4.2vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "palegreen",
                                marginLeft: "0.6vw",
                                borderRadius: "4.2vh",
                                backgroundImage:
                                  "linear-gradient(#1B863A, #105F26)",
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            >
                              {overs.RUNS}
                            </span>
                          </div>
                        </>
                      );
                    } else if (
                      overs.RUNS === 6 &&
                      overs.WICKET === 0 &&
                      overs.EXTRAS === 0
                    ) {
                      return (
                        <>
                          {" "}
                          <div id="t2_single_balls">
                            <span
                              style={{
                                fontSize: "1.5vh",
                                lineHeight: "1.7vh",
                                fontWeight: "700",
                                height: "4.2vh",
                                width: "4.2vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "palegreen",
                                marginLeft: "0.6vw",
                                borderRadius: "4.2vh",
                                backgroundImage:
                                  "linear-gradient(#1B863A, #105F26)",
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            >
                              {overs.RUNS}
                            </span>
                          </div>
                        </>
                      );
                    } else if (overs.WICKET === 1) {
                      return (
                        <>
                          {" "}
                          <div id="t2_single_balls">
                            <span
                              style={{
                                fontSize: "1.5vh",
                                lineHeight: "1.7vh",
                                fontWeight: "700",
                                height: "4.2vh",
                                width: "4.2vh",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "palegreen",
                                marginLeft: "0.6vw",
                                borderRadius: "4.2vh",
                                flexDirection: "column",
                                backgroundImage:
                                  "linear-gradient(#E83F34, #8B140D)",
                                color: "white",
                                textTransform: "uppercase",
                              }}
                            >
                              <div>{overs.RUNS}</div>
                              <div style={{ fontSize: "0.vh" }} className="">
                                w
                              </div>
                            </span>
                          </div>
                        </>
                      );
                    } else
                      return (
                        <>
                          {overs.EXTRAS !== 0 ? (
                            <div id="t2_single_balls">
                              <span
                                style={{
                                  fontSize: "1vh",
                                  lineHeight: "1.7vh",
                                  fontWeight: "700",
                                  height: "4.2vh",
                                  width: "4.2vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "palegreen",
                                  marginLeft: "0.6vw",
                                  borderRadius: "4.2vh",
                                  flexDirection: "column",
                                  backgroundImage:
                                    "linear-gradient(#ECE5D7, #A39585)",
                                  color: "black",
                                  textTransform: "uppercase",
                                }}
                              >
                                <div>{overs.TOTAL_RUNS}</div>
                                <div>{overs.EXTRAS_TYPE}</div>
                              </span>
                            </div>
                          ) : (
                            <div id="t2_single_balls">
                              <span
                                style={{
                                  fontSize: "1.5vh",
                                  lineHeight: "1.7vh",
                                  fontWeight: "700",
                                  height: "4.2vh",
                                  width: "4.2vh",
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                  backgroundColor: "palegreen",
                                  marginLeft: "0.6vw",
                                  borderRadius: "4.2vh",
                                  backgroundImage:
                                    "linear-gradient(#ECE5D7, #A39585)",
                                  color: "black",
                                  textTransform: "uppercase",
                                }}
                              >
                                {overs.RUNS}
                              </span>
                            </div>
                          )}
                        </>
                      );
                  })}
              </div>
            </div>
          </div>
          <div id="t2_right_border_bg"></div>
        </div>
        </>
      )}
    </>
  );
}

export default T2Ticker;
