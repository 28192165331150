import React from "react";

function T4Ticker() {
  return (
    <div>
      <div>Hello Ticker 4</div>
    </div>
  );
}
export default T4Ticker;
