import React, { useEffect, useState } from "react";
// import scorecard_bg from "../../Assets/scorecard_bg.png";
import batting_team_logo from "../../Assets/batting_team_logo.png";
import batting_summary_effect from "../../Assets/batting_summary_effect.png";
import qs from "qs";
import "../../Styles/T1/BowlingSummary.css";
import axios from "axios";
import { useSearchParams } from "react-router-dom";
import team_default_img from "../../Assets/team_logo.png";
import TossBottom from "./TossBottom";
import InningsBreakBottom from "./InningsBreakBottom";
import MatchSummary from "./MatchSummary";
import socketIOClient from "socket.io-client";
import he from "he";
function BowlingSummary() {
  let [searchParams] = useSearchParams();
  let match_id = searchParams.get("match_id");
  const ENDPOINT = process.env.REACT_APP_SOCKET_URL;

  const [summary, setSummary] = useState({
    player_names: [],
    team_logo: "",
    fall_of_wicket: [],
    score: [],
    extras: "",
    runs: "",
    wickets: "",
    team_name: "",
    overs: "",
    team_nick_name: "",
    curr_innings: "",
    status: "",
    curr_innings: "",
    bowler: "",
    result: "",
  });

  const reqData = {
    mod: "Match",
    actionType: "innings-summary-new",
    subAction: JSON.stringify({ MATCH_ID: match_id }),
  };
  useEffect(() => {
    axios({
      method: "POST",
      url: process.env.REACT_APP_API_URL,
      data: qs.stringify(reqData),
      headers: {
        "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    }).then((res) => {
      console.log(res, "res");
      let responseData = res.data.XSCData;
      setSummary({
        player_names: responseData.BOWLING_TEAM_DETAILS.PLAYER_SCORE_DETAILS,
        team_name: he.decode(responseData.BOWLING_TEAM_DETAILS.TEAM_NAME),
        overs: responseData.BOWLING_TEAM_DETAILS.OVERS,
        runs: responseData.BOWLING_TEAM_DETAILS.RUNS,
        fall_of_wicket: responseData.BATTING_TEAM_DETAILS.FALL_OF_WICKET,
        team_logo: responseData.BOWLING_TEAM_DETAILS.TEAM_URL,
        team_nick_name: he.decode(
          responseData.BOWLING_TEAM_DETAILS.TEAM_NICK_NAME
        ),
        curr_innings: responseData.CURRENT_INNINGS,
        wickets: responseData.BOWLING_TEAM_DETAILS.WICKETS,
        extras: responseData.BOWLING_TEAM_DETAILS.EXTRAS,
        status: responseData.STATUS,
      });
    });
  }, [0]);

  const handleImage = (e) => {
    e.target.src = team_default_img;
  };
  const decodeNames = (x) => {
    // var x = "http\\u00253A\\u00252F\\u00252Fexample.com";
    if (x) {
      var r = /\\u([\d\w]{4})/gi;
      x = x.replace(r, function (match, grp) {
        return String.fromCharCode(parseInt(grp, 16));
      });
    }

    return x;
  };
  const [showTicker, setShowTicker] = useState(true);

  useEffect(() => {
    setTimeout(function () {
      setShowTicker(!showTicker);
      // setShow("none");
    }, 10000);
  }, []);
  const maxLetterCount = 24; // Set the maximum letter count

  const bowling_team_name = document.getElementById(
    "bowling_summary_team_name"
  );
  if (bowling_team_name) {
    if (bowling_team_name.textContent.length > maxLetterCount) {
      const truncatedText = bowling_team_name.textContent.substring(
        0,
        maxLetterCount
      );
      bowling_team_name.textContent = truncatedText + "...";
    }
  }

  return (
    <>
      {" "}
      {showTicker ? (
        <div
          data-aos="zoom-in"
          data-aos-delay="500"
          style={{
            // backgroundImage: `url(${scorecard_bg})`,
            height: "100vh",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            // width: "100vw",
          }}
          className="d-flex position-relative"
        >
          <div
            id="bowling_summary_white_bg"
            className="m-auto position-relative"
          >
            {" "}
            <div
              id="bowling_summary_team_name_bg"
              className="position-absolute d-flex justify-content-between align-items-center translate-middle-y"
            >
              <span
                id="bowling_summary_logo_outer_gradient"
                className="position-absolute  z-1 top-0"
              >
                <div id="bowling_summary_logo_inner_gradient">
                  <div
                    id="bowling_summary_team_logo"
                    className="d-flex justify-content-center"
                  >
                    <img
                      style={{
                        height: "7vh",
                        width: "7vh",
                        borderRadius: "2.6vw",
                      }}
                      src={process.env.REACT_APP_API_URL + summary.team_logo}
                      alt="logo"
                      width="100%"
                      onError={handleImage}
                    ></img>
                  </div>
                </div>
              </span>{" "}
              <div id="bowling_summary_team_name" className="">
                {summary.team_nick_name === undefined
                  ? ""
                  : he.decode(decodeNames(summary.team_nick_name))}{" "}
                -{" "}
                {summary.team_name === undefined
                  ? ""
                  : he.decode(decodeNames(summary.team_name))}
              </div>
              <div
                id="bowling_summary_text"
                className="position-absolute bottom-0 end-0"
              >
                {summary.curr_innings === "1"
                  ? "INNINGS 1 - BOWLING SUMMARY"
                  : ""}
                {summary.curr_innings === "2"
                  ? "INNINGS 2 - BOWLING SUMMARY"
                  : ""}
              </div>
            </div>
            <div className="row d-flex align-items-center" id="bowling_content">
              <div className="d-flex align-items-center">
                {" "}
                <div id="bowling_summary_empty_bg" className="col-3"></div>
                <div id="bowling_summary_heading" className="col-9 ">
                  <div
                    className="row d-flex align-items-center"
                    id="bowling_summary_player_runs_text"
                  >
                    <div className="col-1"></div>
                    <div className="col-2" style={{ marginLeft: "2vh" }}>
                      Overs
                    </div>
                    <div className="col-2" style={{ marginLeft: "0vh" }}>
                      DOTS
                    </div>
                    <div className="col-2">runs</div>
                    <div className="col-2">wickets</div>
                    <div className="col-2" style={{ marginLeft: "1vh" }}>
                      economy
                    </div>
                    {/* <div className="col-1"></div> */}
                  </div>
                </div>
              </div>

              {summary.player_names === undefined
                ? ""
                : summary.player_names
                    .filter((player) => player.OVERS !== null)
                    .slice(0, 8)
                    .sort(function (a, b) {
                      return b.RUNS - a.RUNS;
                    })
                    .map((bowler) => {
                      // console.log("hello");
                      //  const filterList = summary.player_names.fil
                      return (
                        <div className="d-flex">
                          {" "}
                          <div
                            id="bowling_summary_player_name"
                            className="col-3 text-white d-flex align-items-center"
                          >
                            {decodeNames(bowler.NAME)}
                          </div>
                          <div
                            id="bowling_summary_player_runs"
                            className="col-9 "
                          >
                            <div
                              className="row d-flex align-items-center"
                              id="bowling_summary_player_runs_text"
                            >
                              <div className="col-1"></div>
                              <div
                                className="col-2"
                                style={{ marginLeft: "1vh" }}
                              >
                                {bowler.OVERS}
                              </div>
                              <div className="col-2">{bowler.DOT_BALLS}</div>
                              <div className="col-2">{bowler.RUNS}</div>
                              <div
                                className="col-2"
                                style={{ marginLeft: "1.5vh" }}
                              >
                                {bowler.WICKETS}
                              </div>
                              <div className="col-2">{bowler.ECONOMY}</div>
                              <div className="col-1"></div>
                            </div>
                          </div>
                        </div>
                      );
                    })}

              <div className="d-flex " id="fall_of_wickets_row">
                {" "}
                <div
                  id="fall_of_wickets_text"
                  className="col-3 text-white d-flex align-items-center"
                >
                  Fall of Wickets
                </div>
                <div id="fall_of_wickets_bg" className="col-9  ">
                  <div className="row ">
                    {summary.fall_of_wicket === undefined ? (
                      ""
                    ) : summary.fall_of_wicket === null ? (
                      <div className="col-1">-</div>
                    ) : (
                      summary.fall_of_wicket.map((wickets) => {
                        return (
                          <div className="col-1" align="center">
                            {wickets.WICKET}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>
              <div className="d-flex " id="scores_row">
                {" "}
                <div
                  id="bowling_summary_player_name"
                  className="col-3 text-white d-flex align-items-center "
                >
                  Scores
                </div>
                <div id="bowling_scores_nums" className="col-9  ">
                  <div className="row">
                    {summary.fall_of_wicket === undefined ? (
                      ""
                    ) : summary.fall_of_wicket === null ? (
                      <div className="col-1">-</div>
                    ) : (
                      summary.fall_of_wicket.map((wickets) => {
                        return (
                          <div className="col-1" align="center">
                            {wickets.TEAM_RUNS}
                          </div>
                        );
                      })
                    )}
                  </div>
                </div>
              </div>

              <div className="row " id="extras_row">
                <div
                  id="bowling_summary_extras_bg"
                  className="col-9 d-flex justify-content-between align-items-center"
                >
                  <div>
                    Extras {summary.extras === null ? "0" : summary.extras}
                  </div>
                  <div className="pe-5 " id="">
                    Overs {summary.overs}
                  </div>
                </div>
                <div
                  id="bowling_summary_runs_bg"
                  className="col-3 d-flex justify-content-center align-items-center  position-absolute  end-0  translate-middle"
                >
                  {summary.runs}-{summary.wickets}{" "}
                </div>
              </div>
            </div>
          </div>

          <div
            id="bowling_outer_gradient_layout"
            className="position-absolute "
          ></div>
          <div
            id="bowling_inner_gradient_layout"
            className="position-absolute"
          ></div>
        </div>
      ) : summary.status === "INNINGS_BREAK" ? (
        <InningsBreakBottom />
      ) : summary.status === "COMPLETED" ? (
        <MatchSummary />
      ) : (
        ""
      )}
    </>
  );
}

export default BowlingSummary;
