import Lottie from "react-lottie";
import React from "react";
import out_lottie from "../../Assets/t3/t3_out.json";

function T3Out() {

  const defaultOptions = {
    animationData: out_lottie,
    autplay: true,
    loop: false,
  };
;
  return (
    <div>
      <Lottie options={defaultOptions} height="100vh"
          width="100vh"
          style={{ backgroundSize: "cover", height: "100vh", width: "100vw" }} />
    </div>
  );
}

export default T3Out;
